// @ts-check
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getContractsAction } from '../redux/actions/contract-actions';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { LoadingContractsComponent } from '../components/loading-contracts-component';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  clientLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  }
}));

function Client({ contract }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Paper
      className={classes.paper}
      elevation={0}
      onClick={() => history.push(`/contracts/${contract.id}/view`)}
    >
      <img className={classes.clientLogo} src={contract.promisor.logo} alt="logo" />

      <div>
        <b>{contract.promisor.name}</b>
      </div>
    </Paper>
  );
}

function CenteredGrid({ contracts }) {
  const renderClients = contracts.map((value, key) => {
    return (
      <Grid key={key} item xs={6} md={4}>
        <Client contract={value} />
      </Grid>
    );
  });

  return (
    <Grid container spacing={2}>
      {renderClients}
    </Grid>
  );
}

export default function ContractsPage() {
  const classes = useStyles();

  const { contracts, loading } = useSelector((state) => state.contracts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContractsAction());
  }, [dispatch]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingContractsComponent />}
          {!loading && (
            <>
              <BackNavigationComponent page="Contracts" uri="/" />
              <CenteredGrid contracts={contracts} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
