// @ts-check
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { getContractAction } from '../../../contracts/presentation/redux/actions/contract-actions';

import { makeStyles } from '@material-ui/core/styles';
import { BackdropComponent } from '../../../../core/utils/components/backdrop-component';
import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';
import { addDeliveryPermissionsAction } from '../redux/actions/driver-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(0)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

export function SelectActiveContract({ driver }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hubs, setHubs] = useState([]);
  const [hubId, setHubId] = useState('');

  const { contracts } = useSelector((state) => state.contracts);
  const { contract, loading } = useSelector((state) => state.contract);

  const list = contracts.map((contract) => ({ value: contract.id, label: contract.promisor.name }));
  // @ts-ignore
  const hlist = hubs.map((hub) => ({ value: hub.id, label: hub.name }));

  useEffect(() => {
    if (contract) {
      setHubs(contract.hubs);
    }
  }, [contract]);

  const handleChange = (event) => {
    dispatch(getContractAction(event.target.value));
    setHubs([]);
    setHubId('');
  };

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: 'success',
    description: 'Driver removed from hub'
  });

  const showSnackbar = (status = 'success', description = 'Driver removed from hub') => {
    setSnackbar(true);
    setMessage({
      status,
      description
    });
    setBackdrop(false);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  // const add = () => {
  //   addDeliveryPermissionsAction()
  // }

  const addPermission = () => {
    setBackdrop(true);
    const props = { closeBackdrop, showSnackbar };
    dispatch(
      addDeliveryPermissionsAction(driver.id, contract.promisor.id, contract.id, hubId, props)
    );
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.margin}
            select
            label="Contract"
            variant="outlined"
            onChange={handleChange}
            fullWidth={true}
          >
            {list.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.margin}
            select
            label="Hub"
            variant="outlined"
            onChange={(event) => setHubId(event.target.value)}
            fullWidth={true}
            disabled={loading}
          >
            {hlist.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {/* {loading && <div>loading</div>} */}
      <div className={classes.margin}>
        <Button
          className={classes.margin}
          type="button"
          variant="contained"
          size="large"
          color="primary"
          fullWidth={true}
          disabled={!hubId}
          onClick={addPermission}
        >
          Add Driver To Hub
        </Button>
      </div>

      <>
        <BackdropComponent open={backdrop} />
        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </div>
  );
}
