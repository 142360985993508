import React, { useEffect, useState } from 'react';
import { isValidNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { createDriverAction, getOrganisationSettingsAction } from '../redux/actions/driver-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Grid, CircularProgress, MenuItem } from '@material-ui/core';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function CreateDriverForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [walletFeature, setWalletFeature] = useState(false);
  const { error, loading } = useSelector((state) => state.driver);
  const { settings } = useSelector((state) => state.get_organisation_settings);
  const { success, me } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getOrganisationSettingsAction());
  }, []);

  useEffect(() => {
    if (success && me) {
      setWalletFeature(me.organization.wallet_feature);
    }
  }, [success, me]);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let vehicle_type_options = settings?.vehicle_types.map((x) => ({
    value: x.toLowerCase(),
    label: x
  }));

  let payment_type_options = [];
  if (walletFeature) {
    payment_type_options = me?.payment_settings?.map((x) => ({
      value: x.id,
      label: x.name
    }));
  }

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('name')}
        id="outlined-full-width"
        label="Name"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('email')}
        id="outlined-full-width"
        label="Email"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('mobile_no')}
        id="outlined-full-width"
        label="Phone no."
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('employee_code')}
        id="outlined-full-width"
        label="Employee Code"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('vehicle_type')}
        id="outlined-select-currency"
        select
        label="Vehicle Type"
        variant="outlined"
        fullWidth={true}
      >
        {vehicle_type_options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      {walletFeature ? (
        <TextField
          {...formikProps('payment_setting')}
          id="outlined-select-currency"
          select
          label="Payment Setting"
          variant="outlined"
          fullWidth={true}
        >
          {payment_type_options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        ''
      )}

      <TextField
        {...formikProps('password')}
        id="outlined-full-width"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        autoComplete="new-password"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Create Driver
            </Button>

            <Button
              className={classes.margin}
              variant="outlined"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={() => history.push('/drivers')}
            >
              Cancel
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  name: Yup.string('Enter driver name').required('Driver name is required'),
  email: Yup.string('Enter driver email address')
    .email('Enter a valid email')
    .required('Email is required'),
  mobile_no: Yup.string('Enter driver phone no.')
    .test('Phone Test', 'Enter a valid phone no., with iso code.', (value) => {
      try {
        return isValidNumber(value) && validatePhoneNumberLength(value) === undefined;
      } catch (err) {
        return true;
      }
    })
    .required('Phone no. is required'),
  employee_code: Yup.string('Enter employee code').required('Employee code is required'),
  vehicle_type: Yup.string('Select vehicle type').required('Vehicle type is required'),
  payment_setting: Yup.string('Select payment type'),
  password: Yup.string('')
    .matches(
      /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/,
      'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit and one special character.'
    )
    .required('Enter your password')
});

function CreateUserFormValidation() {
  const history = useHistory();
  const dispatch = useDispatch();

  const values = {
    name: '',
    email: '',
    mobile_no: '',
    employee_code: '',
    vehicle_type: '',
    payment_setting: '',
    password: ''
  };

  const submit = (data, actions) => {
    const props = { history, actions };
    dispatch(
      createDriverAction(
        data.name,
        data.email,
        data.mobile_no,
        data.vehicle_type,
        data.payment_setting,
        data.employee_code,
        data.password,
        props
      )
    );

    // actions.resetForm();
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <CreateDriverForm {...props} />}
    </Formik>
  );
}

export default function CreateDriverPage() {
  const classes = useStyles();
  // const dispatch = useDispatch();

  // const { settings } = useSelector((state) => state.get_organisation_settings);
  // useEffect(() => {
  //   dispatch(getOrganisationSettingsAction());
  // }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.container}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={8}>
            <BackNavigationComponent
              page="Create driver"
              uri="/"
              path={[
                {
                  page: 'Home',
                  uri: '/'
                },
                {
                  page: 'Drivers',
                  uri: '/drivers'
                }
              ]}
            />

            <CreateUserFormValidation />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
