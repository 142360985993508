// @ts-check
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Button, CircularProgress } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { uploadDriverImageAction } from '../redux/actions/driver-actions';
import Avatar from '@material-ui/core/Avatar/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  },
  driverAvatarContainer: {
    justifyContent: 'center',
    display: 'flex'
  },
  driverAvatar: {
    width: '7rem',
    height: '7rem'
  },
  driverLogo: {
    height: '10rem',
    width: '10rem',
    objectFit: 'contain'
  }
}));

export function UploadDriverImageComponent({ driver }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.upload);

  const [preview, setPreview] = useState(driver.image || '/static/images/avatar/1.jpg');

  const [file, setFile] = useState();

  const handleImageChange = (e) => {
    const _file = e.target.files[0];
    // @ts-ignore
    setPreview(URL.createObjectURL(_file));
    setFile(_file);
  };

  const cancel = () => {
    setFile(undefined);
    setPreview(driver.image);
  };

  const uploadSuccess = () => {
    setFile(undefined);
  };

  const submit = () => {
    if (file) {
      const props = { uploadSuccess };
      dispatch(uploadDriverImageAction(driver.id, file, props));
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.driverAvatarContainer}>
        <Avatar className={classes.driverAvatar} alt={driver.name} src={preview} />
      </div>

      <div>
        <b>{driver.name}</b>
      </div>

      <input
        accept="image/*"
        className={classes.input}
        id="icon-button-file"
        type="file"
        onChange={handleImageChange}
      />

      {!loading && (
        <>
          {!file && (
            <label htmlFor="icon-button-file">
              <IconButton color="primary" aria-label="upload driver logo" component="span">
                <PhotoCamera />
              </IconButton>
            </label>
          )}

          {file && (
            <>
              <label>
                <IconButton
                  color="primary"
                  aria-label="upload driver logo"
                  component="span"
                  onClick={cancel}
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </label>
              <label>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  size="small"
                  onClick={submit}
                >
                  Upload
                </Button>
              </label>
            </>
          )}
        </>
      )}

      {loading && (
        <label>
          <IconButton color="primary" aria-label="upload driver logo" component="span">
            <CircularProgress color="inherit" size={20} />
          </IconButton>
        </label>
      )}
    </div>
  );
}
