import { Dispatch } from 'redux';
import { SET_USER_TOKEN } from '../../../../../core/redux/types/token-types';
import { MeRepositoryImpl } from '../../../data/repositories/me-repository-impl';
import { MeUsecaseImpl } from '../../../domain/usecases/me-usecase';
import { ME_LOAD_REQUEST, ME_LOAD_SUCCESS, ME_LOAD_FAILURE } from '../types/me-types';

export const getMeAction = (token: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SET_USER_TOKEN, payload: token });
    dispatch({ type: ME_LOAD_REQUEST });

    try {
      const meRepository = new MeRepositoryImpl();
      const meUsecase = new MeUsecaseImpl(meRepository);

      const result = await meUsecase.GetMe();

      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: ME_LOAD_FAILURE, error });
    }
  };
};

export const editPaymentSettingsAction = (
  payment_setting_id: string,
  fee_per_trip: string,
  fee_per_order: string,
  base_fee: string,
  additional_fee: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ME_LOAD_REQUEST });

    try {
      const meRepository = new MeRepositoryImpl();
      const meUsecase = new MeUsecaseImpl(meRepository);

      const result = await meUsecase.EditPaymentSettings(
        payment_setting_id,
        fee_per_trip,
        fee_per_order,
        base_fee,
        additional_fee
      );

      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
      props.closeBackdrop();
      props.showSnackbar('success', 'Issuer settings saved');
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', 'error occured!');
    }
  };
};
