import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';

import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { LoadingComponent } from '../../../drivers/presentation/components/loading-component';
import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { editPaymentSettingsAction } from '../../../me/presentation/redux/actions/me-actions';

import { TextField, Button, CircularProgress, InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Minimize } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  maringCancel: {
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  clientLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  icon: {
    fontSize: '4em'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

function EditPaymentTypeForm(props) {
  const classes = useStyles();
  const { error, loading } = useSelector((state) => state.loading);
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            {...formikProps('fee_per_trip')}
            id="outlined-full-width"
            label="FEE PER TRIP: Do you pay your drivers per trip, irrespective of how many orders are in the trip? If yes, capture the amount you pay per trip. If no, leave as 0."
            type="number"
            variant="outlined"
            fullWidth={true}
            InputProps={{
              startAdornment: <InputAdornment position="start">R</InputAdornment>,
              style: { fontSize: 12 }
            }}
            InputLabelProps={{ style: { fontSize: 12 } }}
          />

          <TextField
            {...formikProps('fee_per_order')}
            id="outlined-full-width"
            label="FEE PER ORDER: Do you pay your drivers per order? If yes, capture the amount you pay per order. If no, leave as 0."
            type="number"
            variant="outlined"
            fullWidth={true}
            InputProps={{
              startAdornment: <InputAdornment position="start">R</InputAdornment>,
              style: { fontSize: 12 }
            }}
            InputLabelProps={{ style: { fontSize: 12 } }}
          />

          <TextField
            {...formikProps('base_fee')}
            id="outlined-select-currency"
            type="number"
            label="BASE KM'S: Do you pay your drivers an additional amount per km over and above the trip/order fee? If yes, capture the base amount of km's. If no, leave as 0."
            variant="outlined"
            fullWidth={true}
            InputProps={{
              startAdornment: <InputAdornment position="start">km</InputAdornment>,
              style: { fontSize: 12 }
            }}
            InputLabelProps={{ style: { fontSize: 12 } }}
          />

          <TextField
            {...formikProps('additional_fee')}
            id="outlined-select-currency"
            type="number"
            label="FEE PER ADDITIONAL KM OVER BASE KM: How much per km over and above your base km do you pay? Capture the amount per additional km. If not applicable, leave as 0."
            variant="outlined"
            fullWidth={true}
            InputProps={{
              startAdornment: <InputAdornment position="start">R</InputAdornment>,
              style: { fontSize: 12 }
            }}
            InputLabelProps={{ style: { fontSize: 12 } }}
          />

          <div className={classes.formButtons}>
            {!loading && (
              <>
                <Button
                  className={classes.margin}
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  fullWidth={true}
                >
                  Save
                </Button>
              </>
            )}

            {loading && (
              <Button
                className={classes.margin}
                variant="contained"
                size="large"
                color="primary"
                fullWidth={true}
              >
                <CircularProgress color="inherit" size={26} />
              </Button>
            )}

            {error && (
              <div>
                <span className={classes.errorText}>{error.message}</span>
              </div>
            )}
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default function PaymentTypePage(props) {
  const classes = useStyles();
  // @ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();
  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  const [message, setMessage] = useState({
    status: 'success',
    description: 'Settings Changed'
  });

  const { loading } = useSelector((state) => state.loading);

  const { success, me } = useSelector((state) => state.me);

  const validationSchema = Yup.object({
    fee_per_trip: Yup.number()
      .typeError('You must specify a number for the fee per trip')
      .min(0)
      .integer(),
    fee_per_order: Yup.number()
      .typeError('You must specify a number for the fee per order')
      .min(0)
      .integer(),
    base_fee: Yup.number().typeError('You must specify a number for the base fee').min(0).integer(),
    additional_fee: Yup.number()
      .typeError('You must specify a number for the additional fee')
      .min(0)
      .integer()
  });

  const showSnackbar = (status = 'success', description = 'Settings Changed') => {
    setSnackbar(true);
    setMessage({
      status,
      description
    });
    setBackdrop(false);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const submit = (data, actions) => {
    setBackdrop(true);
    const props = { closeBackdrop, showSnackbar };
    dispatch(
      editPaymentSettingsAction(
        data.id,
        String(data.fee_per_trip),
        String(data.fee_per_order),
        String(data.base_fee),
        String(data.additional_fee),
        props
      )
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.container}>
        {loading && <LoadingComponent />}
        {!loading && success && (
          <>
            <BackNavigationComponent
              page={'Payment Type - ' + me.payment_settings.find((x) => x.id === id).name}
              uri="/settings"
              path={[
                {
                  page: 'Home',
                  uri: '/'
                },
                {
                  page: 'Payment Settings',
                  uri: '/settings'
                }
              ]}
            />
            <div>
              <Formik
                initialValues={me.payment_settings.find((x) => x.id === id)}
                validationSchema={validationSchema}
                onSubmit={submit}
              >
                {(props) => <EditPaymentTypeForm {...props} />}
              </Formik>
            </div>
          </>
        )}
        <>
          <Backdrop
            className={classes.backdrop}
            open={backdrop}
            invisible={false}
            onClick={closeBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <SnackbarComponent
            open={snackbar}
            onClose={closeSnackbar}
            severity={message.status}
            message={message.description}
          />
        </>
      </Container>
    </React.Fragment>
  );
}
