// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    [theme.breakpoints.down('xs')]: {
      bottom: '4rem',
      justifyContent: 'center',
      alignItems: 'center'
    },
    [theme.breakpoints.only('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      right: 0,
      left: 0
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function SnackbarComponent({ open, onClose, severity, message }) {
  const classes = useStyles();

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      autoHideDuration={3000}
      onClose={onClose}
      className={classes.snackbar}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
