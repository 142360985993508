import { Dispatch } from 'redux';
import {
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS
} from '../../../../../core/redux/types/loading-types';

import { DriverRepositoryImpl } from '../../../data/repositories/driver-repository-impl';
import { DriverUsecaseImpl } from '../../../domain/usecases/driver-usecase';
import {
  DELIVERY_PERMISSIONS_LOAD_FAILURE,
  DELIVERY_PERMISSIONS_LOAD_REQUEST,
  DELIVERY_PERMISSIONS_LOAD_SUCCESS
} from '../types/delivery-permissions-types';
import {
  DRIVER_LOAD_REQUEST,
  DRIVER_LOAD_SUCCESS,
  DRIVER_LOAD_FAILURE
} from '../types/driver-types';
import {
  DRIVERS_LOAD_FAILURE,
  DRIVERS_LOAD_REQUEST,
  DRIVERS_LOAD_SUCCESS
} from '../types/drivers-types';
import { UPLOAD_FAILURE, UPLOAD_REQUEST, UPLOAD_SUCCESS } from '../types/upload-types';
import {
  ORGANISATION_SETTINGS_LOAD_REQUEST,
  ORGANISATION_SETTINGS_LOAD_SUCCESS,
  ORGANISATION_SETTINGS_LOAD_FAILURE
} from '../types/organisation-types';

export const getOrganisationSettingsAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ORGANISATION_SETTINGS_LOAD_REQUEST });
    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.GetOrganisationSettings();

      dispatch({ type: ORGANISATION_SETTINGS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: ORGANISATION_SETTINGS_LOAD_FAILURE, error });
    }
  };
};

export const createDriverAction = (
  name: string,
  email: string,
  mobile_no: string,
  vehicle_type: string,
  payment_setting: string,
  employee_code: string,
  password: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DRIVER_LOAD_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.CreateDriver(
        name,
        email,
        mobile_no,
        vehicle_type,
        payment_setting,
        employee_code,
        password
      );

      dispatch({ type: DRIVER_LOAD_SUCCESS, payload: result });
      props.history.push(`/drivers/${result.id}/view`);
    } catch (error) {
      dispatch({ type: DRIVER_LOAD_FAILURE, error });
    }
  };
};

export const editDriverAction = (
  driver_id: string,
  name: string,
  vehicle_type: string,
  payment_setting: string,
  employee_code: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);
      const result = await driverUsecase.EditDriver(
        driver_id,
        name,
        vehicle_type,
        payment_setting,
        employee_code
      );

      dispatch({ type: DRIVER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editDriverEmailAction = (driver_id: string, email: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.EditDriverEmail(driver_id, email);

      dispatch({ type: DRIVER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editDriverPhoneAction = (driver_id: string, mobile_no: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.EditDriverPhone(driver_id, mobile_no);

      dispatch({ type: DRIVER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editDriverPasswordAction = (driver_id: string, password: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.EditDriverPassword(driver_id, password);

      dispatch({ type: DRIVER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const getDriverAction = (driver_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DRIVER_LOAD_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.GetDriver(driver_id);

      dispatch({ type: DRIVER_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: DRIVER_LOAD_FAILURE, error });
    }
  };
};

export const getDriversAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DRIVERS_LOAD_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.GetDrivers();

      dispatch({ type: DRIVERS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: DRIVERS_LOAD_FAILURE, error });
    }
  };
};

export const uploadDriverImageAction = (driver_id: string, file: any, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UPLOAD_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.UploadDriverImage(driver_id, file);

      dispatch({ type: DRIVER_LOAD_SUCCESS, payload: result });
      dispatch({ type: UPLOAD_SUCCESS });
      props.uploadSuccess();
    } catch (error) {
      dispatch({ type: UPLOAD_FAILURE, error });
    }
  };
};

export const getDeliveryPermissionsAction = (driver_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DELIVERY_PERMISSIONS_LOAD_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.GetDeliveryPermissions(driver_id);

      dispatch({ type: DELIVERY_PERMISSIONS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: DELIVERY_PERMISSIONS_LOAD_FAILURE, error });
    }
  };
};

export const addDeliveryPermissionsAction = (
  driver_id: string,
  promisor_id: string,
  contract_id: string,
  hub_id: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.AddDeliveryPermission(
        driver_id,
        promisor_id,
        contract_id,
        hub_id
      );

      dispatch({ type: DELIVERY_PERMISSIONS_LOAD_SUCCESS, payload: result });
      props.closeBackdrop();
      props.showSnackbar('success', 'Driver added to hub');
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', error.message);
    }
  };
};

export const removeDeliveryPermissionsAction = (
  driver_id: string,
  promisor_id: string,
  hub_id: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.RemoveDeliveryPermission(driver_id, promisor_id, hub_id);

      dispatch({ type: DELIVERY_PERMISSIONS_LOAD_SUCCESS, payload: result });
      props.closeBackdrop();
      props.showSnackbar();
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', error.message);
    }
  };
};

export const blockDriverAction = (driver_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    // dispatch({ type: LOADING_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.BlockDriver(driver_id);

      dispatch({ type: DRIVER_LOAD_SUCCESS, payload: result });
      // dispatch({ type: LOADING_SUCCESS });
      props.closeBackdrop();
      // props.showSnackbar();
    } catch (error) {
      // dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const unblockDriverAction = (driver_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    // dispatch({ type: LOADING_REQUEST });

    try {
      const driverRepository = new DriverRepositoryImpl();
      const driverUsecase = new DriverUsecaseImpl(driverRepository);

      const result = await driverUsecase.UnblockDriver(driver_id);

      dispatch({ type: DRIVER_LOAD_SUCCESS, payload: result });
      // dispatch({ type: LOADING_SUCCESS });
      props.closeBackdrop();
      // props.showSnackbar();
    } catch (error) {
      // dispatch({ type: LOADING_FAILURE, error });
    }
  };
};
