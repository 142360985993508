import { DeliveryPermission } from '../../domain/entities/delivery-permissions-entity';
import { Driver } from '../../domain/entities/driver-entity';
import { Settings } from '../../domain/entities/settings-entity';
import { DriverRepository } from '../../domain/repositories/driver-repository';
import { createDriver } from '../datasources/create-driver';
import { editDriver } from '../datasources/edit-driver';
import { getDriver } from '../datasources/get-driver';
import { getDrivers } from '../datasources/get-drivers';
import { getDeliveryPermissions } from '../datasources/get-delivery-permissions';
import { uploadDriverImage } from '../datasources/upload-driver-image';
import { editDriverPhone } from '../datasources/edit-driver-phone';
import { editDriverEmail } from '../datasources/edit-driver-email';
import { unblockDriver } from '../datasources/unblock-driver';
import { blockDriver } from '../datasources/block-driver';
import { editDriverPassword } from '../datasources/edit-driver-password';
import { removeDeliveryPermission } from '../datasources/remove-delivery-permission';
import { addDeliveryPermission } from '../datasources/add-delivery-permission';
import { getOrganisationSettings } from '../datasources/get-organisation';

export class DriverRepositoryImpl implements DriverRepository {
  async CreateDriver(
    name: string,
    email: string,
    mobile_no: string,
    vehicle_type: string,
    payment_setting: string,
    employee_code: string,
    password: string
  ): Promise<Driver> {
    const result = await createDriver(
      name,
      email,
      mobile_no,
      vehicle_type,
      payment_setting,
      employee_code,
      password
    );

    return new Driver(result);
  }

  async EditDriver(
    driver_id: string,
    name: string,
    vehicle_type: string,
    payment_setting: string,
    employee_code: string
  ): Promise<Driver> {
    const result = await editDriver(driver_id, name, vehicle_type, payment_setting, employee_code);

    return new Driver(result);
  }

  async EditDriverEmail(driver_id: string, email: string): Promise<Driver> {
    const result = await editDriverEmail(driver_id, email);

    return new Driver(result);
  }

  async EditDriverPhone(driver_id: string, mobile_no: string): Promise<Driver> {
    const result = await editDriverPhone(driver_id, mobile_no);

    return new Driver(result);
  }

  async EditDriverPassword(driver_id: string, password: string): Promise<Driver> {
    const result = await editDriverPassword(driver_id, password);

    return new Driver(result);
  }

  async BlockDriver(driver_id: string): Promise<Driver> {
    const result = await blockDriver(driver_id);

    return new Driver(result);
  }

  async UnblockDriver(driver_id: string): Promise<Driver> {
    const result = await unblockDriver(driver_id);

    return new Driver(result);
  }

  async GetDrivers(): Promise<Driver[]> {
    const result = await getDrivers();

    return result.map((driver: any) => new Driver(driver));
  }

  async GetDriver(driver_id: string): Promise<Driver> {
    const result = await getDriver(driver_id);

    return new Driver(result);
  }

  async UploadDriverImage(driver_id: string, file: any): Promise<Driver> {
    await uploadDriverImage(driver_id, file);
    const result = await getDriver(driver_id);

    return new Driver(result);
  }

  async GetDeliveryPermissions(driver_id: string): Promise<DeliveryPermission[]> {
    const result = await getDeliveryPermissions(driver_id);

    return result.map(
      (permission: any) => new DeliveryPermission(permission.promisor, permission.hub)
    );
  }

  async AddDeliveryPermission(
    driver_id: string,
    promisor_id: string,
    contract_id: string,
    hub_id: string
  ): Promise<DeliveryPermission[]> {
    const result = await addDeliveryPermission(driver_id, promisor_id, contract_id, hub_id);

    return result.map(
      (permission: any) => new DeliveryPermission(permission.promisor, permission.hub)
    );
  }

  async RemoveDeliveryPermission(
    driver_id: string,
    promisor_id: string,
    hub_id: string
  ): Promise<DeliveryPermission[]> {
    const result = await removeDeliveryPermission(driver_id, promisor_id, hub_id);

    return result.map(
      (permission: any) => new DeliveryPermission(permission.promisor, permission.hub)
    );
  }

  async GetOrganisationSettings(): Promise<Settings> {
    const result = await getOrganisationSettings();

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.sms_enabled,
      result.vehicle_types
    );
  }
}
