import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import token from './reducers/token-reducers';
import loading from './reducers/loading-reducers';

import login from '../../features/login/presentation/redux/reducers/login-reducers';
import me from '../../features/me/presentation/redux/reducers/me-reducer';
import organization from '../../features/login/presentation/redux/reducers/organization-reducers';

import driver from '../../features/drivers/presentation/redux/reducers/driver-reducer';
import upload from '../../features/drivers/presentation/redux/reducers/upload-reducer';
import drivers from '../../features/drivers/presentation/redux/reducers/drivers-reducer';
import delivery_permissions from '../../features/drivers/presentation/redux/reducers/delivery-permissions-reducer';
import get_organisation_settings from '../../features/drivers/presentation/redux/reducers/organisation-reducer';

import contracts from '../../features/contracts/presentation/redux/reducers/contracts-reducer';
import contract from '../../features/contracts/presentation/redux/reducers/contract-reducer';
import contract_hub_drivers from '../../features/contracts/presentation/redux/reducers/contract-hub-drivers-reducer';

// Setup Redux store with Thunks
const reducers = combineReducers({
  token,
  loading,
  me,
  login,
  organization,
  driver,
  upload,
  drivers,
  delivery_permissions,
  get_organisation_settings,
  contracts,
  contract,
  contract_hub_drivers
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APPLICATION_STATE') {
    state = undefined;
  }

  return reducers(state, action);
};
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
export type AppState = ReturnType<typeof rootReducer>;
