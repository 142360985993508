import { Dispatch } from 'redux';

import {
  CONTRACTS_LOAD_REQUEST,
  CONTRACTS_LOAD_SUCCESS,
  CONTRACTS_LOAD_FAILURE
} from '../types/contracts-types';

import {
  CONTRACT_LOAD_REQUEST,
  CONTRACT_LOAD_SUCCESS,
  CONTRACT_LOAD_FAILURE,
  CONTRACT_LOAD_AVAILABLE_DRIVERS
} from '../types/contract-types';

import {
  CONTRACT_HUB_DRIVERS_ADD,
  CONTRACT_HUB_DRIVERS_LOAD_FAILURE,
  CONTRACT_HUB_DRIVERS_LOAD_REQUEST,
  CONTRACT_HUB_DRIVERS_LOAD_SUCCESS,
  CONTRACT_HUB_DRIVERS_REMOVE
} from '../types/contract-hub-drivers-types';

import {
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS
} from '../../../../../core/redux/types/loading-types';

import { ContractUsecaseImpl } from '../../../domain/usecases/contract-usecases';
import { ContractRepositoryImpl } from '../../../data/repositories/contract-repository-impl';

export const getContractsAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CONTRACTS_LOAD_REQUEST });

    try {
      const contractRepository = new ContractRepositoryImpl();
      const contractUsecase = new ContractUsecaseImpl(contractRepository);

      const result = await contractUsecase.GetContracts();

      dispatch({ type: CONTRACTS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: CONTRACTS_LOAD_FAILURE, error });
    }
  };
};

export const getContractAction = (contract_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CONTRACT_LOAD_REQUEST });

    try {
      const contractRepository = new ContractRepositoryImpl();
      const contractUsecase = new ContractUsecaseImpl(contractRepository);

      const result = await contractUsecase.GetContract(contract_id);
      const drivers = await contractUsecase.GetAssignedDrivers(contract_id);

      dispatch({ type: CONTRACT_LOAD_SUCCESS, payload: result });
      dispatch({ type: CONTRACT_LOAD_AVAILABLE_DRIVERS, payload: drivers });
    } catch (error) {
      dispatch({ type: CONTRACT_LOAD_FAILURE, error });
    }
  };
};

export const getDriversAssignedToHubAction = (
  promisor_id: string,
  contract_id: string,
  hub_id: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CONTRACT_HUB_DRIVERS_LOAD_REQUEST });

    try {
      const contractRepository = new ContractRepositoryImpl();
      const contractUsecase = new ContractUsecaseImpl(contractRepository);

      const result = await contractUsecase.GetDriversAssignedToHub(
        promisor_id,
        contract_id,
        hub_id
      );

      dispatch({ type: CONTRACT_HUB_DRIVERS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: CONTRACT_HUB_DRIVERS_LOAD_FAILURE, error });
    }
  };
};

export const addDriverToHubAction = (
  promisor_id: string,
  contract_id: string,
  driver_id: string,
  hub_id: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const contractRepository = new ContractRepositoryImpl();
      const contractUsecase = new ContractUsecaseImpl(contractRepository);

      const result = await contractUsecase.AddDriverToHub(
        promisor_id,
        contract_id,
        driver_id,
        hub_id
      );

      props.closeBackdrop();
      props.showSnackbar();

      dispatch({ type: CONTRACT_HUB_DRIVERS_ADD, payload: result });
      // dispatch({ type: CONTRACT_AVAILABLE_DRIVERS_REMOVE, payload: result });
      dispatch({ type: LOADING_SUCCESS });
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar();
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const removeDriverFromHubAction = (
  promisor_id: string,
  contract_id: string,
  driver_id: string,
  hub_id: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const contractRepository = new ContractRepositoryImpl();
      const contractUsecase = new ContractUsecaseImpl(contractRepository);

      const result = await contractUsecase.RemoveDriverFromHub(
        promisor_id,
        contract_id,
        driver_id,
        hub_id
      );

      props.closeBackdrop();
      props.showSnackbar();

      dispatch({ type: CONTRACT_HUB_DRIVERS_REMOVE, payload: result });
      // dispatch({ type: CONTRACT_AVAILABLE_DRIVERS_ADD, payload: result });
      dispatch({ type: LOADING_SUCCESS });
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar();
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};
