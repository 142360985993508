import { Login } from '../../domain/entities/login-entity';
import { Organization } from '../../domain/entities/organization-entity';
import { LoginRepository } from '../../domain/repositories/login-repository';
import { findOrganization } from '../datasources/find-organization';
import { login } from '../datasources/login';

export class LoginRepositoryImpl implements LoginRepository {
  async FindOrganization(organization: string): Promise<Organization> {
    const result = await findOrganization(organization);

    return new Organization(result.client_id, result.name, result.organization, result.logo);
  }

  async Login(client_id: string, email: string, password: string): Promise<Login> {
    const { token, expires_in, token_type } = await login(client_id, email, password);

    return new Login(token, expires_in, token_type);
  }
}
