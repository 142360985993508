import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';
import store from '../../../../core/redux/store';

export const getContracts = async (): Promise<any> => {
  try {
    const state = store.getState();
    const token = state.token.token;

    const uri = `${config.uri.base_url}/contracts`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      })
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Something went wrong');
    }

    return result;
  } catch (err) {
    throw err;
  }
};
