// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';

import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import Container from '@material-ui/core/Container/Container';
import Hidden from '@material-ui/core/Hidden/Hidden';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { SimpleBottomNavigation } from './bottom-navigation';
import { Apps } from './apps';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(0)
  },
  logo: {
    height: '1.9rem'
  },
  edgePadding: {
    padding: theme.spacing(0)
  },
  section: {
    display: 'flex'
  },
  icon: {
    height: '1.9rem',
    // width: '8rem',
    objectFit: 'contain'
  },
  divider: {
    marginRight: '8px !important',
    marginLeft: '8px !important'
  }
}));

function PrimarySearchAppBar() {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [logo, setLogo] = useState('');
  const [walletFeature, setWalletFeature] = useState(false);
  const { config } = useSelector((state) => state.token);

  const { success, me } = useSelector((state) => state.me);

  useEffect(() => {
    if (success && me) {
      setLogo(me.organization.logo);
      setWalletFeature(me.organization.wallet_feature);
    }
  }, [success, me]);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Apps />
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" color="default">
        <Container>
          <Toolbar className={classes.edgePadding}>
            {/* {mobileSearch && (
              <>
                <IconButton
                  aria-label="back to see nav"
                  onClick={() => setMobileSearch(false)}
                  color="inherit"
                >
                  <ArrowBackIcon />
                </IconButton>

                <SearchDriversComponent />
              </>
            )} */}

            <>
              <Button color="inherit" onClick={() => history.push('/')}>
                <>
                  <img className={classes.icon} src={config.images.default_logo} alt="logo" />
                </>
                <Divider orientation="vertical" flexItem className={classes.divider} />
                <img
                  className={classes.icon}
                  src={
                    'https://storage.googleapis.com/cowabunga-public-images/asserts/Fleet-Icon.PNG'
                  }
                  alt="logo"
                />
                <Divider orientation="vertical" flexItem className={classes.divider} />
                <img className={classes.logo} src={logo} alt="logo" />
              </Button>

              <div className={classes.grow} />
              {walletFeature ? (
                <IconButton onClick={() => history.push('/settings')} color="inherit">
                  <AccountBalanceWalletIcon />
                </IconButton>
              ) : (
                ''
              )}
              <div className={classes.section}>
                <Hidden xsDown>
                  <Button color="inherit" onClick={() => history.push('/contracts')}>
                    <small>
                      <b>Contracts</b>
                    </small>
                  </Button>
                </Hidden>

                <Hidden xsDown>
                  <Button color="inherit" onClick={() => history.push('/drivers')}>
                    <small>
                      <b>Drivers</b>
                    </small>
                  </Button>
                </Hidden>

                <IconButton
                  aria-label="show 4 new mails"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AppsOutlinedIcon />
                </IconButton>

                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  onClick={() => history.push('/account')}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
            </>
          </Toolbar>
        </Container>
      </AppBar>
      {renderMenu}
    </div>
  );
}

export default function NavBarComponent(props) {
  return (
    <>
      <PrimarySearchAppBar />
      {props.children}
      <Hidden smUp>
        <SimpleBottomNavigation />
      </Hidden>
    </>
  );
}
