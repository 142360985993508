// @ts-check
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { ListItemSecondaryAction } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { EditDriverFormComponent } from './edit-driver-form-component';

import DriveEta from '@material-ui/icons/DriveEta';
import Work from '@material-ui/icons/Work';
import LocalShipping from '@material-ui/icons/LocalShipping';
import DirectionsBike from '@material-ui/icons/DirectionsBike';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },

  heading: {
    margin: theme.spacing(2)
  }
}));

function EmployeeCarType({ driver, walletFeature }) {
  const classes = useStyles();
  const getAvataricon = (driver) => {
    switch (driver.vehicle_type) {
      case 'bike':
        return <DirectionsBike />;
      case 'car':
        return <DriveEta />;
      case 'bakkie':
        return <LocalShipping />;
      default:
        return <DriveEta />;
    }
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemIcon>
            <Work />
          </ListItemIcon>
          <ListItemText
            primary={<small>Employee Code</small>}
            secondary={driver.employee_code}
          ></ListItemText>
        </ListItem>

        <ListItem>
          <ListItemIcon>{getAvataricon(driver)}</ListItemIcon>
          <ListItemText
            primary={<small>Vehicle Type</small>}
            secondary={driver.vehicle_type}
          ></ListItemText>
        </ListItem>

        {walletFeature ? (
          <ListItem>
            <ListItemIcon>
              <AccountBalanceWalletIcon />{' '}
            </ListItemIcon>
            <ListItemText
              primary={<small>Payment Setting</small>}
              secondary={driver.payment_setting ? driver.payment_setting.name : 'Default'}
            ></ListItemText>
          </ListItem>
        ) : (
          ''
        )}
      </List>
    </>
  );
}

function DriverInformation({ driver }) {
  const classes = useStyles();
  const [walletFeature, setWalletFeature] = useState(false);
  const [open, setOpen] = useState(false);

  const { success, me } = useSelector((state) => state.me);

  useEffect(() => {
    if (success && me) {
      setWalletFeature(me.organization.wallet_feature);
    }
  }, [success, me]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <Avatar alt={driver.name} src={driver.image || '/static/images/avatar/1.jpg'} />
          </ListItemAvatar>
          <ListItemText primary={`${driver.name}`} secondary={null}></ListItemText>

          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {!open && (
        <>
          <br />
          <EmployeeCarType driver={driver} walletFeature={walletFeature} />
        </>
      )}

      {open && (
        <>
          <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
            <small>
              <b>Edit Driver</b>
            </small>
          </Typography>
        </>
      )}

      {open && <EditDriverFormComponent driver={driver} handleClose={handleClose} />}
    </>
  );
}

export function DriverInformationComponent({ driver }) {
  return (
    <div>
      <DriverInformation driver={driver} />
    </div>
  );
}
