import { Me } from '../entities/me-entity';
import { MeRepository } from '../repositories/me-repository';

interface MeUsecase {
  GetMe(): Promise<Me>;
  EditPaymentSettings(
    payment_setting_id: string,
    fee_per_trip: string,
    fee_per_order: string,
    base_fee: string,
    additional_fee: string
  ): Promise<Me>;
}

export class MeUsecaseImpl implements MeUsecase {
  meRepository: MeRepository;

  constructor(mr: MeRepository) {
    this.meRepository = mr;
  }

  GetMe(): Promise<Me> {
    return this.meRepository.GetMe();
  }

  EditPaymentSettings(
    payment_setting_id: string,
    fee_per_trip: string,
    fee_per_order: string,
    base_fee: string,
    additional_fee: string
  ): Promise<Me> {
    return this.meRepository.EditPaymentSettings(
      payment_setting_id,
      fee_per_trip,
      fee_per_order,
      base_fee,
      additional_fee
    );
  }
}
