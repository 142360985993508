import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';

export const uploadDriverImage = async (driver_id: string, file: any): Promise<any> => {
  try {
    const body = new FormData();
    body.append('file', file);
    body.append('driver_id', driver_id);

    const uri = `${config.uri.upload_url}/upload-driver-image`;
    const options = {
      method: 'POST',
      headers: new Headers({
        // Authorization: `Bearer ${token}`
      }),
      body: body
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Something went wrong');
    }

    return result;
  } catch (err) {
    throw err;
  }
};
