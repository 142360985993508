// @ts-check
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, ListItemSecondaryAction, Typography } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import {
  getDeliveryPermissionsAction,
  removeDeliveryPermissionsAction
} from '../redux/actions/driver-actions';
import { LoadingDeliveryPermissionsComponent } from './loading-delivery-permissions-component';
import { BackdropComponent } from '../../../../core/utils/components/backdrop-component';
import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';
import { SelectActiveContract } from './add-delivery-permission';
import { getContractsAction } from '../../../contracts/presentation/redux/actions/contract-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  driverLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  }
}));

function DeliveryPermissions({ driver, permissions }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: 'success',
    description: 'Driver removed from hub'
  });

  const showSnackbar = (status = 'success', description = 'Driver removed from hub') => {
    setSnackbar(true);
    setMessage({
      status,
      description
    });
    setBackdrop(false);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const removePermission = (permission) => {
    setBackdrop(true);
    const props = { closeBackdrop, showSnackbar };
    dispatch(
      removeDeliveryPermissionsAction(driver.id, permission.promisor.id, permission.hub.id, props)
    );
  };

  const n = permissions.length - 1;
  const parties = permissions
    .sort((permission_a, permission_b) => {
      if (permission_a.hub.name < permission_b.hub.name) {
        return -1;
      }
      if (permission_a.hub.name > permission_b.hub.name) {
        return 1;
      }
      return 0;
    })
    .map((permission, index) => {
      return (
        <div key={index}>
          <ListItem button>
            <ListItemText
              primary={`${permission.promisor.name}`}
              secondary={permission.hub.name}
            ></ListItemText>

            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() => removePermission(permission)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {index !== n && <Divider />}
        </div>
      );
    });

  return (
    <div className={classes.root}>
      {permissions.length === 0 && <small>no permissions</small>}
      {permissions.length > 0 && <List className={classes.root}>{parties}</List>}

      <>
        <BackdropComponent open={backdrop} />
        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </div>
  );
}

export function DeliveryPermissionsComponent({ driver }) {
  const classes = useStyles();

  const { permissions, loading } = useSelector((state) => state.delivery_permissions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeliveryPermissionsAction(driver.id));
    dispatch(getContractsAction());
  }, [dispatch, driver.id]);

  return (
    <div>
      {loading && <LoadingDeliveryPermissionsComponent />}
      {!loading && (
        <>
          <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
            <small>
              <b>Delivery Permissions</b>
            </small>
          </Typography>

          <SelectActiveContract driver={driver} />

          <DeliveryPermissions driver={driver} permissions={permissions} />
        </>
      )}
    </div>
  );
}
