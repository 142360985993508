import { Login } from '../entities/login-entity';
import { Organization } from '../entities/organization-entity';
import { LoginRepository } from '../repositories/login-repository';

interface LoginUsecase {
  FindOrganization(organization: string): Promise<Organization>;
  Login(client_id: string, email: string, password: string): Promise<Login>;
}

export class LoginUsecaseImpl implements LoginUsecase {
  loginRepository: LoginRepository;

  constructor(lr: LoginRepository) {
    this.loginRepository = lr;
  }

  FindOrganization(organization: string): Promise<Organization> {
    return this.loginRepository.FindOrganization(organization);
  }

  Login(client_id: string, email: string, password: string): Promise<Login> {
    return this.loginRepository.Login(client_id, email, password);
  }
}
