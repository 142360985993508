import { config } from '../../config/config';
import { REMOVE_USER_TOKEN, SET_USER_TOKEN } from '../types/token-types';

const initialState = {
  token: undefined,
  config: config,
  logo: 'http://cowabunga.co.za/static/img/logo.svg',
  base_url: `https://cowabunga-fleet-management-api-62njna2wya-ew.a.run.app`,
  // base_url: `http://localhost:8080`,
  upload_url: `https://cowabunga-image-upload-service-62njna2wya-ew.a.run.app`
};

function token(state = initialState, action: any = null) {
  switch (action.type) {
    case SET_USER_TOKEN:
      return {
        ...state,
        token: action.payload
      };

    case REMOVE_USER_TOKEN:
      return {
        ...state,
        token: undefined
      };

    default:
      return state;
  }
}

export default token;
