class PaymentSetting {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class Driver {
  id: string;
  name: string;
  email: string;
  image: string;
  mobile_no: string;
  vehicle_type: string;
  employee_code: string;
  blocked: boolean;
  status: string;
  payment_setting: PaymentSetting;
  wallet_payment_type: string;
  hub_ids: string;
  hubs: string;

  constructor(args: any) {
    this.id = args.id;
    this.name = args.name;
    this.email = args.email;
    this.image = args.image;
    this.mobile_no = args.mobile_no;
    this.vehicle_type = args.vehicle_type;
    this.employee_code = args.employee_code;
    this.blocked = args.blocked;
    this.status = args.blocked ? 'Suspended' : 'Active';
    this.payment_setting = new PaymentSetting(args.payment_setting.id, args.payment_setting.name);
    this.wallet_payment_type = args.wallet_payment_type;
    this.hub_ids = args.hub_ids;
    this.hubs = args.hubs;
  }
}
