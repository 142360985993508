import {
  ORGANIZATION_LOAD_REQUEST,
  ORGANIZATION_LOAD_SUCCESS,
  ORGANIZATION_LOAD_FAILURE,
  ORGANIZATION_LOAD_DEFAULT
} from '../types/organization-types';

const initialState = {
  loading: false,
  error: null,
  data: null
};

function organization(state = initialState, action: any = null) {
  switch (action.type) {
    case ORGANIZATION_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ORGANIZATION_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case ORGANIZATION_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };

    case ORGANIZATION_LOAD_DEFAULT:
      return {
        ...state,
        loading: false,
        error: null,
        data: null
      };

    default:
      return state;
  }
}

export default organization;
