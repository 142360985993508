import { Me } from '../../domain/entities/me-entity';
import { MeRepository } from '../../domain/repositories/me-repository';
import { getMe } from '../datasources/get-me';
import { editPaymentSettings } from '../datasources/edit-payment-settings';

export class MeRepositoryImpl implements MeRepository {
  async GetMe(): Promise<Me> {
    const result = await getMe();

    return new Me(result);
  }

  async EditPaymentSettings(
    payment_setting_id: string,
    fee_per_trip: string,
    fee_per_order: string,
    base_fee: string,
    additional_fee: string
  ): Promise<Me> {
    const result = await editPaymentSettings(
      payment_setting_id,
      fee_per_trip,
      fee_per_order,
      base_fee,
      additional_fee
    );

    return new Me(result);
  }
}
