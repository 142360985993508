import { PaymentSetting } from '../entities/payment-setting-entity';
import { PaymentSettingRepository } from '../repositories/payment-setting-repository';

interface PaymentSettingUsecase {
  CreatePaymentSetting(
    name: string,
    fee_per_trip: string,
    fee_per_order: string,
    base_fee: string,
    additional_fee: string
  ): Promise<PaymentSetting>;
}

export class PaymentSettingUsecaseImpl implements PaymentSettingUsecase {
  paymentSettingRepository: PaymentSettingRepository;

  constructor(ps: PaymentSettingRepository) {
    this.paymentSettingRepository = ps;
  }

  CreatePaymentSetting(
    name: string,
    fee_per_trip: string,
    fee_per_order: string,
    base_fee: string,
    additional_fee: string
  ): Promise<PaymentSetting> {
    return this.paymentSettingRepository.CreatePaymentSetting(
      name,
      fee_per_trip,
      fee_per_order,
      base_fee,
      additional_fee
    );
  }
}
