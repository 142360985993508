// @ts-check
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import NavBarComponent from './components/navbar-component';
import { AccessComponent } from './components/access-component';

import HomePage from '../../features/home/presentation/pages/home-page';

import DriversPage from '../../features/drivers/presentation/pages/drivers-page';
import DriverPage from '../../features/drivers/presentation/pages/driver-page';
import CreateDriverPage from '../../features/drivers/presentation/pages/create-driver-page';

import SettingsPage from '../../features/settings/presentation/pages/settings-page';
import CreateSettingPage from '../../features/settings/presentation/pages/create-setting-page';
import PaymentTypePage from '../../features/settings/presentation/pages/payment-types-page';
import ContractsPage from '../../features/contracts/presentation/pages/contracts-page';
import ContractPage from '../../features/contracts/presentation/pages/contract-page';

import { ProfilePage } from '../../features/me/presentation/pages/profile-page';

export default () => {
  return (
    <Router>
      <AccessComponent>
        <Switch>
          <NavBarComponent>
            <Route exact path="/" component={HomePage} />

            <Route exact path="/settings" component={SettingsPage} />
            <Route path="/settings/create" component={CreateSettingPage} />
            <Route path="/settings/:id/payment-types" component={PaymentTypePage} />

            <Route exact path="/contracts" component={ContractsPage} />
            <Route path="/contracts/:id/view" component={ContractPage} />

            <Route path="/account" component={ProfilePage} />

            <Route exact path="/drivers" component={DriversPage} />
            <Route path="/drivers/create" component={CreateDriverPage} />
            <Route path="/drivers/:id/view" component={DriverPage} />
          </NavBarComponent>
        </Switch>
      </AccessComponent>
    </Router>
  );
};
