import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemSecondaryAction } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { LoadingComponent } from '../../../drivers/presentation/components/loading-component';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';
import { FloatingActionButtonSize } from '../components/add-component';
import Divider from '@material-ui/core/Divider';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { getMeAction } from '../../../me/presentation/redux/actions/me-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  clientLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  icon: {
    fontSize: '4em'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export default function SettingsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [payment_settings, setPaymentSettings] = useState([]);
  const { success, me, loading } = useSelector((state) => state.me);

  useEffect(() => {
    if (success && me) {
      setPaymentSettings(me.payment_settings);
    }
  }, [success, me]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && (
            <>
              <BackNavigationComponent
                page="Payment Settings"
                uri="/"
                path={[
                  {
                    page: 'Home',
                    uri: '/'
                  }
                ]}
              />
              <Grid container spacing={2}>
                {payment_settings?.map((payment_setting, index) => {
                  return (
                    <Grid item xs={4}>
                      <Paper className={classes.paper} elevation={0}>
                        <List className={classes.root}>
                          <ListItem>
                            <ListItemText>{payment_setting.name}</ListItemText>

                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="comments"
                                onClick={() =>
                                  history.push(`/settings/${payment_setting.id}/payment-types`)
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
              <FloatingActionButtonSize />
              <Divider className={classes.divider} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
