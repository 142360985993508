export class Organization {
  client_id: string;
  name: string;
  organization: string;
  logo: string;

  constructor(client_id: string, name: string, organization: string, logo: string) {
    this.client_id = client_id;
    this.name = name;
    this.organization = organization;
    this.logo = logo;
  }
}
