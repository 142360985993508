class Organization {
  id: string;
  name: string;
  logo: string;
  wallet_feature: boolean;

  /**
   * Organization entity constructor
   * @constructor
   * @param {string} id
   * @param {string} name
   * @param {string} organization
   * @param {string} logo
   * @param {boolean} wallet_feature
   */
  constructor(id: string, name: string, logo: string, wallet_feature: boolean) {
    this.id = id;
    this.name = name;
    this.logo = logo;
    this.wallet_feature = wallet_feature;
  }
}

class PaymentSetting {
  id: string;
  name: string;
  fee_per_trip: number;
  fee_per_order: number;
  base_fee: number;
  additional_fee: number;

  constructor(
    id: string,
    name: string,
    fee_per_trip: number,
    fee_per_order: number,
    base_fee: number,
    additional_fee: number
  ) {
    this.id = id;
    this.name = name;
    this.fee_per_trip = fee_per_trip;
    this.fee_per_order = fee_per_order;
    this.base_fee = base_fee;
    this.additional_fee = additional_fee;
  }
}

export class Me {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  mobile_no: string;
  image: string;
  organization: Organization;
  payment_settings: PaymentSetting[];

  constructor(args: any) {
    const payment_settings = args.payment_settings || [];

    this.id = args.id;
    this.firstname = args.firstname;
    this.lastname = args.lastname;
    this.email = args.email;
    this.mobile_no = args.mobile_no;
    this.image = args.image;
    this.organization = new Organization(
      args.organization.id,
      args.organization.name,
      args.organization.logo,
      args.organization.wallet_feature
    );
    this.payment_settings = payment_settings.map(
      (x) =>
        new PaymentSetting(
          x.id,
          x.name,
          x.fee_per_trip,
          x.fee_per_order,
          x.base_fee,
          x.additional_fee
        )
    );
  }
}
