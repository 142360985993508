import { PaymentSetting } from '../../domain/entities/payment-setting-entity';
import { PaymentSettingRepository } from '../../domain/repositories/payment-setting-repository';
import { createPaymentSetting } from '../datasources/create-payment-setting';

export class PaymentSettingRepositoryImpl implements PaymentSettingRepository {
  async CreatePaymentSetting(
    name: string,
    fee_per_trip: string,
    fee_per_order: string,
    base_fee: string,
    additional_fee: string
  ): Promise<PaymentSetting> {
    const result = await createPaymentSetting(
      name,
      fee_per_trip,
      fee_per_order,
      base_fee,
      additional_fee
    );

    return new PaymentSetting(result);
  }
}
