export class Hub {
  id: string;
  name: string;

  /**
   * Hub entity constructor
   * @constructor
   * @param hub
   */
  constructor(hub: any) {
    this.id = hub.id;
    this.name = hub.name;
  }
}
