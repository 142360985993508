class Promisor {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

class Hub {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class DeliveryPermission {
  promisor: Promisor;
  hub: Hub;

  constructor(promisor: any, hub: any) {
    this.promisor = new Promisor(promisor.id, promisor.name);
    this.hub = new Hub(hub.id, hub.name);
  }
}
