import { getDrivers } from '../../../drivers/data/datasources/get-drivers';
import { Driver } from '../../../drivers/domain/entities/driver-entity';
import { Contract } from '../../domain/entities/contract-entity';
import { ContractRepository } from '../../domain/repositories/contract-repository';
import { addDriverToHub } from '../datasources/add-driver-to-hub';
import { getContract } from '../datasources/get-contract';

import { getContracts } from '../datasources/get-contracts';
import { getDriversAssignedToHub } from '../datasources/get-drivers-assigned-to-hub';
import { removeDriverFromHub } from '../datasources/remove-driver-from-hub';

export class ContractRepositoryImpl implements ContractRepository {
  async GetContracts(): Promise<Contract[]> {
    const contracts = await getContracts();

    return contracts.map(
      (contract: any) => new Contract(contract.id, contract.valid, contract.promisor)
    );
  }

  async GetContract(contract_id: string): Promise<Contract> {
    const result = await getContract(contract_id);

    return new Contract(result.id, result.valid, result.promisor, result.hubs);
  }

  async GetAssignedDrivers(contract_id: string): Promise<Driver[]> {
    // const assigned_drivers = await getAssignedDrivers(contract_id);
    const drivers = await getDrivers();
    const available_drivers = drivers;

    /*const [drivers, assigned_drivers] = await Promise.all([
      getDrivers(),
      getAssignedDrivers(contract_id)
    ]);

    const available_drivers = drivers.filter(
      ({ id: id1 }) => !assigned_drivers.some(({ id: id2 }) => id2 === id1)
    );*/

    return available_drivers.map((driver: any) => new Driver(driver));
  }

  async GetDriversAssignedToHub(
    promisor_id: string,
    contract_id: string,
    hub_id: string
  ): Promise<Driver[]> {
    const result = await getDriversAssignedToHub(promisor_id, contract_id, hub_id);

    return result.map((driver: any) => new Driver(driver));
  }

  async AddDriverToHub(
    promisor_id: string,
    contract_id: string,
    driver_id: string,
    hub_id: string
  ): Promise<Driver> {
    const result = await addDriverToHub(promisor_id, contract_id, driver_id, hub_id);

    return new Driver(result);
  }

  async RemoveDriverFromHub(
    promisor_id: string,
    contract_id: string,
    driver_id: string,
    hub_id: string
  ): Promise<Driver> {
    const result = await removeDriverFromHub(promisor_id, contract_id, driver_id, hub_id);

    return new Driver(result);
  }
}
