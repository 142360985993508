// @ts-check
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { editDriverAction, getOrganisationSettingsAction } from '../redux/actions/driver-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function EditDriverForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.loading);
  const [walletFeature, setWalletFeature] = useState(false);
  const { success, me } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getOrganisationSettingsAction());
  }, []);

  useEffect(() => {
    if (success && me) {
      setWalletFeature(me.organization.wallet_feature);
    }
  }, [success, me]);

  const { settings } = useSelector((state) => state.get_organisation_settings);
  const { values, errors, touched, handleSubmit, handleChange, handleBlur, handleClose } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  let vehicle_type_options = settings?.vehicle_types.map((x) => ({
    value: x.toLowerCase(),
    label: x
  }));

  let payment_type_options = [];
  if (walletFeature) {
    payment_type_options = me?.payment_settings?.map((x) => ({
      value: x.id,
      label: x.name
    }));
  }

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('name')}
        id="outlined-full-width"
        label="Name"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('employee_code')}
        id="outlined-full-width"
        label="Employee Code"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('vehicle_type')}
        id="outlined-select-currency"
        select
        label="Vehicle Type"
        variant="outlined"
        fullWidth={true}
      >
        {vehicle_type_options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      {walletFeature ? (
        <TextField
          {...formikProps('payment_setting')}
          id="outlined-select-currency"
          select
          label="Payment Setting"
          variant="outlined"
          fullWidth={true}
        >
          {payment_type_options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        ''
      )}

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Save Changes
            </Button>

            <Button
              className={classes.margin}
              variant="outlined"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  name: Yup.string('Enter driver name').required('Driver name is required'),
  employee_code: Yup.string('Enter employee code').required('Employee code is required'),
  vehicle_type: Yup.string('Select vehicle type').required('Vehicle type is required'),
  payment_setting: Yup.string('Select vehicle type')
});

export function EditDriverFormComponent({ driver, handleClose }) {
  const dispatch = useDispatch();

  const values = {
    name: driver.name,
    email: driver.email,
    mobile_no: driver.mobile_no,
    employee_code: driver.employee_code,
    vehicle_type: driver.vehicle_type,
    payment_setting: driver.payment_setting.id
  };

  const submit = (data) => {
    const props = { handleClose };
    dispatch(
      editDriverAction(
        driver.id,
        data.name,
        data.vehicle_type,
        data.payment_setting,
        data.employee_code,
        props
      )
    );
  };

  return (
    <div>
      <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
        {(props) => <EditDriverForm {...props} handleClose={handleClose} />}
      </Formik>
    </div>
  );
}
