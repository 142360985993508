// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography } from '@material-ui/core';

function Media(props) {
  return (
    <div>
      <Box my={1} justifyContent="center">
        <Typography variant="subtitle1" component="h3" align="center">
          <Skeleton variant="text" />
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {Array.from(new Array(2)).map((item, index) => (
          <Grid key={index} item xs={12} md={12}>
            <Box key={index} width={'100%'} marginRight={0.5} my={3}>
              <Skeleton variant="rect" width={'100%'} height={'8rem'} />

              <Box pt={0.5}>
                <Skeleton />
                <Skeleton width="100%" />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

Media.propTypes = {
  loading: PropTypes.bool
};

export function LoadingDeliveryPermissionsComponent() {
  return (
    <Box overflow="hidden">
      <Media loading />
    </Box>
  );
}
