// @ts-check
import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/actions/login-actions';
import { useHistory } from 'react-router-dom';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Grid,
  CircularProgress,
  Typography,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { FindOrganizationPage } from '../components/find-organization-component';
import { resetOrganizationAction } from '../redux/actions/organization-action';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    }
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  logo: {
    height: '3rem'
  },
  logoContainer: {
    textAlign: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(10)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  },
  clientLogo: {
    height: '5rem',
    width: '5rem',
    objectFit: 'contain'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  }
}));

export function Login(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.login);

  // const onSave = (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   setTimeout(() => {
  //     setLoading(false);
  //     history.push('/');
  //   }, 1000);
  // };

  const {
    values,
    // values: { name, email, password, confirmPassword },
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
    // isValid,
    // setFieldTouched
  } = props;
  // console.table(props);

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  const [showPassword, setshowPassword] = useState(false);

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('email')}
        id="outlined-full-width"
        label="Email"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('password')}
        id="outlined-password-input"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        autoComplete="current-password"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setshowPassword(!showPassword)}>
                {showPassword ? (
                  <VisibilityOffOutlinedIcon color="primary" />
                ) : (
                  <VisibilityOutlinedIcon color="primary" />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
              // disabled={!isValid}
            >
              Sign In
            </Button>

            <Button
              className={classes.margin}
              variant="outlined"
              size="large"
              // color="secondary"
              fullWidth={true}
              onClick={() => dispatch(resetOrganizationAction())}
            >
              Back
            </Button>
          </>
        )}

        {loading && (
          <Button variant="contained" size="large" color="primary" fullWidth={true}>
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  email: Yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  password: Yup.string('')
    .min(8, 'Password must contain atleast 8 characters')
    .required('Enter your password')
});

function FormValidation({ client_id }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const values = { email: '', password: '' };

  const submit = (data) => {
    const props = { history };

    dispatch(login(client_id, data.email, data.password, props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <Login {...props} />}
    </Formik>
  );
}

function LoginComponent({ organization }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={organization.logo} alt="logo" />
      </div>

      <Typography variant="subtitle1" component="h3" align="center">
        <b>{organization.name}</b>
      </Typography>

      {/* <Client organization={organization} /> */}

      <FormValidation client_id={organization.client_id} />
    </React.Fragment>
  );
}

export default function LoginPage() {
  const organization = useSelector((state) => state.organization);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} sm={7} md={5}>
            {!organization.data && <FindOrganizationPage />}
            {organization.data && <LoginComponent organization={organization.data} />}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
