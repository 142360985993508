// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemSecondaryAction } from '@material-ui/core';

import Chip from '@material-ui/core/Chip/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

export function ContractStatusComponent({ contract }) {
  const classes = useStyles();

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemText primary={`${contract.promisor.name}`} secondary={null}></ListItemText>

          <ListItemSecondaryAction>
            <Chip color="primary" size="small" label="Contract" />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
}
