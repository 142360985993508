import {
  DRIVERS_LOAD_REQUEST,
  DRIVERS_LOAD_SUCCESS,
  DRIVERS_LOAD_FAILURE
} from '../types/drivers-types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  drivers: []
};

function drivers(state = initialState, action: any = null) {
  switch (action.type) {
    case DRIVERS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case DRIVERS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case DRIVERS_LOAD_SUCCESS:
      return {
        ...state,
        drivers: action.payload,
        success: true,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

export default drivers;
