import {
  ORGANISATION_SETTINGS_LOAD_REQUEST,
  ORGANISATION_SETTINGS_LOAD_SUCCESS,
  ORGANISATION_SETTINGS_LOAD_FAILURE
} from '../types/organisation-types';

const initialState = {
  loading: false,
  success: false,
  error: null,
  settings: { vehicle_types: [] }
};

function get_organisation_settings(state = initialState, action: any = null) {
  switch (action.type) {
    case ORGANISATION_SETTINGS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      };

    case ORGANISATION_SETTINGS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error
      };

    case ORGANISATION_SETTINGS_LOAD_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        loading: false,
        success: true,
        error: null
      };

    default:
      return state;
  }
}

export default get_organisation_settings;
