class Pooling {
  enabled: boolean;
  algorithm: string;

  constructor(enabled: boolean, algorithm: string) {
    this.enabled = enabled;
    this.algorithm = algorithm;
  }
}

class ClusterLocking {
  strategy: string;
  time: number;

  constructor(strategy: string, time: number = 0) {
    this.strategy = strategy;
    this.time = time;
  }
}

class Cluster {
  enabled: boolean;
  distance: number;
  size: number;
  lock: ClusterLocking;

  constructor(enabled: boolean, distance: number, size: number, lock: any) {
    this.enabled = enabled;
    this.distance = distance;
    this.size = size;
    this.lock = new ClusterLocking(lock.strategy, lock.time);
  }
}

export class Settings {
  scan: boolean;
  cluster: Cluster;
  pooling: Pooling;
  ratings: string[];
  abandonment_reasons: string[];
  sms_enabled: boolean;
  vehicle_types: string[];

  constructor(
    scan: boolean,
    cluster: any,
    pooling: any,
    ratings: string[],
    abandonment_reasons: string[],
    sms_enabled: boolean,
    vehicle_types: string[]
  ) {
    this.scan = scan;
    this.ratings = ratings.map((rating: string) => rating);
    this.abandonment_reasons = abandonment_reasons.map((rating: string) => rating);
    this.cluster = new Cluster(cluster.enabled, cluster.distance, cluster.size, cluster.lock);
    this.pooling = new Pooling(pooling.enabled, pooling.algorithm);
    this.sms_enabled = sms_enabled;
    this.vehicle_types = vehicle_types.map((vehicle_type: string) => vehicle_type);
  }
}
