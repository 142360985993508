// @ts-check
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { ListItemSecondaryAction } from '@material-ui/core';

import Chip from '@material-ui/core/Chip/Chip';
import Button from '@material-ui/core/Button/Button';
import { blockDriverAction, unblockDriverAction } from '../redux/actions/driver-actions';

import { BackdropComponent } from '../../../../core/utils/components/backdrop-component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2)
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  }
}));

function BlockDriver({ driver }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [backdrop, setBackdrop] = useState(false);

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const block = () => {
    setBackdrop(true);
    const props = { closeBackdrop };
    dispatch(blockDriverAction(driver.id, props));
  };

  const unblock = () => {
    setBackdrop(true);
    const props = { closeBackdrop };
    dispatch(unblockDriverAction(driver.id, props));
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <Chip color="primary" size="small" label={driver.status} />
          </ListItemAvatar>

          <ListItemSecondaryAction>
            {!driver.blocked && (
              <Button
                type="button"
                variant="outlined"
                size="small"
                color="secondary"
                onClick={block}
              >
                Block
              </Button>
            )}
            {driver.blocked && (
              <Button
                type="button"
                variant="contained"
                size="small"
                color="secondary"
                onClick={unblock}
              >
                Unblock
              </Button>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <>
        <BackdropComponent open={backdrop} />
      </>
    </>
  );
}

export function BlockDriverComponent({ driver }) {
  return (
    <div>
      <BlockDriver driver={driver} />
    </div>
  );
}
