import { Dispatch } from 'redux';
import { SET_USER_TOKEN } from '../../../../../core/redux/types/token-types';
import { LoginRepositoryImpl } from '../../../data/repositories/login-repository-impl';
import { LoginUsecaseImpl } from '../../../domain/usecases/login-usecase';
import { LOGIN_LOAD_REQUEST, LOGIN_LOAD_SUCCESS, LOGIN_LOAD_FAILURE } from '../types/login-types';
import { ORGANIZATION_LOAD_DEFAULT } from '../types/organization-types';

export const login = (client_id: string, email: string, password: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOGIN_LOAD_REQUEST });

    try {
      const loginRepository = new LoginRepositoryImpl();
      const loginUsecase = new LoginUsecaseImpl(loginRepository);

      const result = await loginUsecase.Login(client_id, email, password);

      localStorage.setItem('cowabunga-client-user', result.token);

      dispatch({ type: LOGIN_LOAD_SUCCESS, payload: result });
      dispatch({ type: SET_USER_TOKEN, payload: result.token });
      dispatch({ type: ORGANIZATION_LOAD_DEFAULT });
      props.history.push(`/`);
    } catch (error) {
      dispatch({ type: LOGIN_LOAD_FAILURE, error });
    }
  };
};
