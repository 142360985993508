// @ts-check
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, ListItemSecondaryAction, Typography } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import { LoadingAssignedDrivers } from './loading-assigned-drivers';
import { SelectHubComponent } from './select-hub-component';

import ListItemAvatar from '@material-ui/core/ListItemAvatar/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar/Avatar';

import Backdrop from '@material-ui/core/Backdrop/Backdrop';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {
  getDriversAssignedToHubAction,
  removeDriverFromHubAction
} from '../redux/actions/contract-actions';

import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { AssignDriversToHubComponent } from './assign-drivers-to-hub';
import { SelectedHubContext } from './selected-hub-context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  driverLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Driver({ driver, handleToggle }) {
  return (
    <>
      <ListItem button>
        <ListItemAvatar>
          <Avatar src={driver.image} alt={driver.name} />
        </ListItemAvatar>
        <ListItemText
          primary={`${driver.name}`}
          // secondary={driver.hub.name}
        ></ListItemText>

        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="comments" onClick={() => handleToggle(driver.id)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}

function Permissions({ drivers, handleToggle }) {
  const classes = useStyles();

  const n = drivers.length - 1;
  const parties = drivers.map((driver, index) => {
    return (
      <div key={index}>
        <Driver driver={driver} handleToggle={handleToggle} />

        {index !== n && <Divider />}
      </div>
    );
  });

  return (
    <>
      <List className={classes.root}>{parties}</List>
    </>
  );
}

function AssignedDrivers({ drivers, contract }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const asigned_drivers = drivers;

  const { state } = useContext(SelectedHubContext);

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  const { error } = useSelector((state) => state.loading);

  const showSnackbar = () => {
    setSnackbar(true);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const handleToggle = (driver_id) => {
    setBackdrop(true);
    const props = { closeBackdrop, showSnackbar };
    dispatch(
      removeDriverFromHubAction(contract.promisor.id, contract.id, driver_id, state.hub.id, props)
    );
  };

  return (
    <>
      <>
        <Backdrop
          className={classes.backdrop}
          open={backdrop}
          invisible={false}
          onClick={closeBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={snackbar} autoHideDuration={5000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity={error ? 'error' : 'success'}>
            {error ? error.message : 'Driver removed from hub!'}
          </Alert>
        </Snackbar>
      </>
      <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
        <small>
          <b>Assigned Drivers</b>
        </small>
      </Typography>
      <div className={classes.root}>
        {asigned_drivers.length === 0 && (
          <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
            <small>no drivers assigned to this hub</small>
          </Typography>
        )}
        {asigned_drivers.length > 0 && (
          <Permissions drivers={drivers} handleToggle={handleToggle} />
        )}
      </div>
    </>
  );
}

export function ContractDriversComponent({ contract }) {
  const classes = useStyles();

  const contract_hub_drivers = useSelector((state) => state.contract_hub_drivers);

  const dispatch = useDispatch();

  const reducer = (state, pair) => ({ ...state, ...pair });
  const initialState = {
    hub: contract['hubs'][0]
  };

  const [state, update] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.hub) {
      dispatch(getDriversAssignedToHubAction(contract.promisor.id, contract.id, state.hub.id));
    }
  }, [state, dispatch, contract]);

  return (
    <div>
      <SelectedHubContext.Provider value={{ state, update }}>
        <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
          <small>
            <b>Select a hub</b>
          </small>
        </Typography>

        <SelectHubComponent contract={contract} />

        {contract_hub_drivers.loading && <LoadingAssignedDrivers />}
        {!contract_hub_drivers.loading && state && (
          <>
            <AssignedDrivers
              drivers={contract_hub_drivers.contract_hub_drivers}
              contract={contract}
            />
            <AssignDriversToHubComponent contract={contract} />
          </>
        )}
      </SelectedHubContext.Provider>
    </div>
  );
}
