import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';

export const findOrganization = async (organization: string): Promise<any> => {
  try {
    const uri = `${config.uri.auth_url}/organization/find-organization?organization=${organization}`;

    const options = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Something went wrong');
    }

    return result;
  } catch (err) {
    throw err;
  }
};
