export class Login {
  token: string;
  expires_in: number;
  token_type: string;

  constructor(token: string, expires_in: number, token_type: string) {
    this.token = token;
    this.expires_in = expires_in;
    this.token_type = token_type;
  }
}
