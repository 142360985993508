import { Dispatch } from 'redux';
import { LoginRepositoryImpl } from '../../../data/repositories/login-repository-impl';
import { LoginUsecaseImpl } from '../../../domain/usecases/login-usecase';
import {
  ORGANIZATION_LOAD_REQUEST,
  ORGANIZATION_LOAD_SUCCESS,
  ORGANIZATION_LOAD_FAILURE,
  ORGANIZATION_LOAD_DEFAULT
} from '../types/organization-types';

export const findOrganizationAction = (organization: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ORGANIZATION_LOAD_REQUEST });

    try {
      const loginRepository = new LoginRepositoryImpl();
      const loginUsecase = new LoginUsecaseImpl(loginRepository);

      const result = await loginUsecase.FindOrganization(organization);

      dispatch({ type: ORGANIZATION_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: ORGANIZATION_LOAD_FAILURE, error });
    }
  };
};

export const resetOrganizationAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ORGANIZATION_LOAD_DEFAULT });
  };
};
