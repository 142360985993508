// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 5,
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(9)
    }
  }
}));

export function FloatingActionButtonSize() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <div className={classes.fab}>
        <Fab color="primary" aria-label="add" onClick={() => history.push('/drivers/create')}>
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
}
