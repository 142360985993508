import { Dispatch } from 'redux';

import { PaymentSettingRepositoryImpl } from '../../../data/repositories/payment-setting-repository-impl';
import { PaymentSettingUsecaseImpl } from '../../../domain/usecases/payment-setting-usecase';

import {
  PAYMENT_SETTING_LOAD_REQUEST,
  PAYMENT_SETTING_LOAD_SUCCESS,
  PAYMENT_SETTING_LOAD_FAILURE
} from '../types/payment-setting-types';

export const createPaymentSettingAction = (
  name: string,
  fee_per_trip: string,
  fee_per_order: string,
  base_fee: string,
  additional_fee: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PAYMENT_SETTING_LOAD_REQUEST });

    try {
      const paymentSettingRepository = new PaymentSettingRepositoryImpl();
      const paymentSettingUsecase = new PaymentSettingUsecaseImpl(paymentSettingRepository);

      const result = await paymentSettingUsecase.CreatePaymentSetting(
        name,
        fee_per_trip,
        fee_per_order,
        base_fee,
        additional_fee
      );

      await dispatch({ type: PAYMENT_SETTING_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: PAYMENT_SETTING_LOAD_FAILURE, error });
    }
  };
};
