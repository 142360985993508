import React, { useEffect, useState } from 'react';
import { isValidNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { createPaymentSettingAction } from '../redux/actions/payment-setting-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Grid, CircularProgress, MenuItem } from '@material-ui/core';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { Visibility, VisibilityOff, Minimize } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';
import { getMeAction } from '../../../me/presentation/redux/actions/me-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function CreateSettingForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const { error, loading } = useSelector((state) => state.driver);
  const { settings } = useSelector((state) => state.get_organisation_settings);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let vehicle_type_options = settings?.vehicle_types.map((x) => ({
    value: x.toLowerCase(),
    label: x
  }));

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('name')}
        id="outlined-full-width"
        label="Name"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('fee_per_trip')}
        id="outlined-select-currency"
        label="FEE PER TRIP: Do you pay your drivers per trip, irrespective of how many orders are in the trip? If yes, capture the amount you pay per trip. If no, leave as 0."
        type="number"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">R</InputAdornment>,
          style: { fontSize: 12 }
        }}
        InputLabelProps={{ style: { fontSize: 12 } }}
      />

      <TextField
        {...formikProps('fee_per_order')}
        id="outlined-select-currency"
        label="FEE PER ORDER: Do you pay your drivers per order? If yes, capture the amount you pay per order. If no, leave as 0."
        type="number"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">R</InputAdornment>,
          style: { fontSize: 12 }
        }}
        InputLabelProps={{ style: { fontSize: 12 } }}
      />

      <TextField
        {...formikProps('base_fee')}
        id="outlined-select-currency"
        label="BASE KM'S: Do you pay your drivers an additional amount per km over and above the trip/order fee? If yes, capture the base amount of km's. If no, leave as 0."
        type="number"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">KM</InputAdornment>,
          style: { fontSize: 12 }
        }}
        InputLabelProps={{ style: { fontSize: 12 } }}
      />

      <TextField
        {...formikProps('additional_fee')}
        id="outlined-select-currency"
        label="FEE PER ADDITIONAL KM OVER BASE KM: How much per km over and above your base km do you pay? Capture the amount per additional km. If not applicable, leave as 0."
        type="number"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">R</InputAdornment>,
          style: { fontSize: 12 }
        }}
        InputLabelProps={{ style: { fontSize: 12 } }}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Create Payment Setting
            </Button>

            <Button
              className={classes.margin}
              variant="outlined"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={() => history.push('/drivers')}
            >
              Cancel
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  name: Yup.string('Enter driver name').required('Payment setting name is required'),
  fee_per_trip: Yup.number('Enter fee per trip').min(0).required('Fee per trip is required'),
  fee_per_order: Yup.number('Enter fee per order').min(0).required('Fee per order is required'),
  base_fee: Yup.number('Enter base fee').min(0).required('Base fee is required'),
  additional_fee: Yup.number('Enter additional fee').min(0).required('Additional fee is required')
});

function CreateUserFormValidation() {
  const history = useHistory();
  const dispatch = useDispatch();

  const values = {
    name: '',
    fee_per_trip: '0',
    fee_per_order: '0',
    base_fee: '0',
    additional_fee: '0'
  };

  const submit = async (data, actions) => {
    const props = { history, actions };
    const bearer = localStorage.getItem('cowabunga-client-user');

    await dispatch(
      createPaymentSettingAction(
        data.name,
        String(data.fee_per_trip),
        String(data.fee_per_order),
        String(data.base_fee),
        String(data.additional_fee),
        props
      )
    );
    await dispatch(getMeAction(bearer));
    history.push('/settings');
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <CreateSettingForm {...props} />}
    </Formik>
  );
}

export default function CreateSettingPage() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.container}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={8}>
            <BackNavigationComponent
              page="Create setting"
              uri="/"
              path={[
                {
                  page: 'Home',
                  uri: '/'
                },
                {
                  page: 'Setting',
                  uri: '/setting'
                }
              ]}
            />

            <CreateUserFormValidation />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
