// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    // width: 500
    marginTop: '4.5rem'
  },
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0
  }
});

export function SimpleBottomNavigation() {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <>
      <div className={classes.root}></div>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.stickToBottom}
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          onClick={() => history.push('/')}
        />
        <BottomNavigationAction
          label="Drivers"
          icon={<GroupIcon />}
          onClick={() => history.push('/drivers')}
        />
        <BottomNavigationAction
          label="Account"
          icon={<AccountCircleIcon />}
          onClick={() => history.push('/account')}
        />
      </BottomNavigation>
    </>
  );
}
