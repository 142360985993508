// @ts-check
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getDriverAction } from '../redux/actions/driver-actions';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { Paper } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import { LoadingComponent } from '../components/loading-component';
import { UploadDriverImageComponent } from '../components/upload-logo-component';
import { DriverInformationComponent } from '../components/driver-information-component';
import { DriverDetailsComponent } from '../components/driver-details-component';
import { DeliveryPermissionsComponent } from '../components/delivery-permissions-component';
import { BlockDriverComponent } from '../components/block-driver-component';
import { DriverPasswordComponent } from '../components/driver-password-component';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(3)
  },
  driverHeader: {
    textAlign: 'center'
  },
  driverLogo: {
    height: '10rem',
    width: '10rem',
    objectFit: 'contain'
  },
  large: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  }
}));

function Client({ driver }) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <UploadDriverImageComponent driver={driver} />
      </Paper>
    </>
  );
}

function CenteredGrid({ driver }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Client driver={driver} />
        <BlockDriverComponent driver={driver} />
      </Grid>

      <Grid item xs={12} md={8}>
        <DriverInformationComponent driver={driver} />
        <DriverPasswordComponent driver={driver} />
        <DriverDetailsComponent driver={driver} />
        <DeliveryPermissionsComponent driver={driver} />
      </Grid>
    </Grid>
  );
}

export default function DriverPage() {
  // @ts-ignore
  const { id } = useParams();
  const classes = useStyles();

  const { driver, loading, success } = useSelector((state) => state.driver);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDriverAction(id));
  }, [dispatch, id]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Driver"
                uri="/drivers"
                path={[
                  {
                    page: 'Home',
                    uri: '/'
                  },
                  {
                    page: 'Drivers',
                    uri: '/drivers'
                  }
                ]}
              />
              <CenteredGrid driver={driver} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
