// @ts-check
import React, { useContext } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem } from '@material-ui/core';

import { SelectedHubContext } from './selected-hub-context';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(0)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function SelectHubForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, hubs } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('hub_id')}
        id="outlined-select-currency"
        select
        label="Hub"
        variant="outlined"
        fullWidth={true}
        onChange={(e) => {
          handleChange(e);
          handleSubmit();
        }}
      >
        {hubs.map((hub) => (
          <MenuItem key={hub.id} value={hub.id}>
            {hub.name}
          </MenuItem>
        ))}
      </TextField>
    </form>
  );
}

const validationSchema = Yup.object({
  hub_id: Yup.string('Select a hub').required('Hub required')
});

export function SelectHubComponent({ contract }) {
  const { state, update } = useContext(SelectedHubContext);
  const values = { hub_id: state.hub.id };

  const submit = (data) => {
    const hub = contract.hubs.find((hub) => hub.id === data.hub_id);
    update({ hub });
  };

  return (
    <>
      <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
        {(props) => <SelectHubForm {...props} hubs={contract.hubs} />}
      </Formik>
    </>
  );
}
