// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: theme.spacing(2)
  }
}));

export function EmptyComponent({ message }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
        <small>
          <b>{message}</b>
        </small>
      </Typography>
    </React.Fragment>
  );
}
