// @ts-check
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(3)
  },
  contractHeader: {
    textAlign: 'center'
  },
  clientLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  large: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  }
}));

export function ContractImageComponent({ contract }) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <img className={classes.clientLogo} src={contract.promisor.logo} alt="logo" />

        <div>
          <b>{contract.promisor.name}</b>
        </div>
      </Paper>
    </>
  );
}
