import { Hub } from './hub-entity';

class Party {
  id: string;
  name: string;
  organization: string;
  logo: string;

  constructor(client: any) {
    this.id = client.id;
    this.name = client.name;
    this.organization = client.organization;
    this.logo = client.logo;
  }
}

export class Contract {
  id: string;
  valid: boolean;
  promisor: Party;
  hubs?: Hub[];

  constructor(id: string, valid: boolean, promisor: any, hubs?: any[]) {
    this.id = id;
    this.valid = valid;
    this.promisor = new Party(promisor);
    this.hubs = hubs && hubs.map((hub) => new Hub(hub));
  }
}
