// @ts-check
import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper/Paper';
import Typography from '@material-ui/core/Typography/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  heading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  clientLogo: {
    height: '3rem',
    // width: '8rem',
    objectFit: 'contain'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
}));

export function Apps({ header = false }) {
  const classes = useStyles();

  const { config } = useSelector((state) => state.token);

  return (
    <div>
      {header && (
        <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
          <small>
            <b>Apps</b>
          </small>
        </Typography>
      )}
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={4}>
          <Paper className={classes.paper} elevation={0}>
            <a
              className={classes.link}
              target="_blank"
              href={config.uri.dos_app}
              rel="noopener noreferrer"
            >
              <img className={classes.clientLogo} src={config.images.app_dos_icon} alt="logo" />
              <div>
                <b>DOS</b>
              </div>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper} elevation={0}>
            <a
              className={classes.link}
              target="_blank"
              href={config.uri.client_admin_app}
              rel="noopener noreferrer"
            >
              <img className={classes.clientLogo} src={config.images.app_admin_icon} alt="logo" />
              <div>
                <b>Admin</b>
              </div>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper} elevation={0}>
            <a className={classes.link} href="/" rel="noopener noreferrer">
              <img className={classes.clientLogo} src={config.images.app_fleet_icon} alt="logo" />
              <div>
                <b>Fleet</b>
              </div>
            </a>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
