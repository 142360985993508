// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from 'react-router-dom';
import { ListItemSecondaryAction } from '@material-ui/core';

import { EmptyComponent } from '../../../../core/utils/components/empty-component';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  granted: {
    color: '#4caf50'
  }
}));

export function AlignItemsList({ drivers }) {
  const classes = useStyles();
  const history = useHistory();

  const n = drivers.length - 1;
  const renderUsers = drivers.map((driver, index) => {
    return (
      <div key={index}>
        <ListItem
          alignItems="flex-start"
          button
          onClick={() => history.push(`/drivers/${driver.id}/view`)}
        >
          <ListItemAvatar>
            <Avatar alt={driver.name} src={driver.image || '/static/images/avatar/1.jpg'} />
          </ListItemAvatar>
          <ListItemText
            primary={`${driver.name}`}
            secondary={<React.Fragment>{driver.email}</React.Fragment>}
          />

          <ListItemSecondaryAction>
            {!driver.blocked && <Chip color="primary" size="small" label={driver.status} />}
            {driver.blocked && <Chip color="secondary" size="small" label={'Blocked'} />}
          </ListItemSecondaryAction>
        </ListItem>
        {index !== n && <Divider variant="inset" component="li" />}
      </div>
    );
  });

  return (
    <>
      <List className={classes.root}>
        {renderUsers}

        {drivers.length === 0 && <EmptyComponent message={'No drivers'} />}
      </List>
    </>
  );
}
