import { DriverRepository } from '../repositories/driver-repository';
import { Driver } from '../entities/driver-entity';
import { DeliveryPermission } from '../entities/delivery-permissions-entity';
import { Settings } from '../entities/settings-entity';

interface DriverUsecase {
  CreateDriver(
    name: string,
    email: string,
    mobile_no: string,
    vehicle_type: string,
    payment_setting: string,
    employee_code: string,
    password: string
  ): Promise<Driver>;
  EditDriver(
    driver_id: string,
    name: string,
    vehicle_type: string,
    payment_setting: string,
    employee_code: string
  ): Promise<Driver>;
  EditDriverEmail(driver_id: string, email: string): Promise<Driver>;
  EditDriverPhone(driver_id: string, mobile_no: string): Promise<Driver>;
  EditDriverPassword(driver_id: string, password: string): Promise<Driver>;
  BlockDriver(driver_id: string): Promise<Driver>;
  UnblockDriver(driver_id: string): Promise<Driver>;
  GetDrivers(): Promise<Driver[]>;
  GetDriver(driver_id: string): Promise<Driver>;
  UploadDriverImage(driver_id: string, file: any): Promise<Driver>;
  GetDeliveryPermissions(driver_id: string): Promise<DeliveryPermission[]>;
  AddDeliveryPermission(
    driver_id: string,
    promisor_id: string,
    contract_id: string,
    hub_id: string
  ): Promise<DeliveryPermission[]>;
  RemoveDeliveryPermission(
    driver_id: string,
    promisor_id: string,
    hub_id: string
  ): Promise<DeliveryPermission[]>;
  GetOrganisationSettings(): Promise<Settings>;
}

export class DriverUsecaseImpl implements DriverUsecase {
  driverRepository: DriverRepository;

  constructor(dr: DriverRepository) {
    this.driverRepository = dr;
  }

  CreateDriver(
    name: string,
    email: string,
    mobile_no: string,
    vehicle_type: string,
    payment_setting: string,
    employee_code: string,
    password: string
  ): Promise<Driver> {
    return this.driverRepository.CreateDriver(
      name,
      email,
      mobile_no,
      vehicle_type,
      payment_setting,
      employee_code,
      password
    );
  }

  EditDriver(
    driver_id: string,
    name: string,
    vehicle_type: string,
    payment_setting: string,
    employee_code: string
  ): Promise<Driver> {
    return this.driverRepository.EditDriver(
      driver_id,
      name,
      vehicle_type,
      payment_setting,
      employee_code
    );
  }

  EditDriverEmail(driver_id: string, email: string): Promise<Driver> {
    return this.driverRepository.EditDriverEmail(driver_id, email);
  }

  EditDriverPhone(driver_id: string, mobile_no: string): Promise<Driver> {
    return this.driverRepository.EditDriverPhone(driver_id, mobile_no);
  }

  EditDriverPassword(driver_id: string, password: string): Promise<Driver> {
    return this.driverRepository.EditDriverPassword(driver_id, password);
  }

  BlockDriver(driver_id: string): Promise<Driver> {
    return this.driverRepository.BlockDriver(driver_id);
  }

  UnblockDriver(driver_id: string): Promise<Driver> {
    return this.driverRepository.UnblockDriver(driver_id);
  }

  GetDrivers(): Promise<Driver[]> {
    return this.driverRepository.GetDrivers();
  }

  GetDriver(driver_id: string): Promise<Driver> {
    return this.driverRepository.GetDriver(driver_id);
  }

  UploadDriverImage(driver_id: string, file: any): Promise<Driver> {
    return this.driverRepository.UploadDriverImage(driver_id, file);
  }

  GetDeliveryPermissions(driver_id: string): Promise<DeliveryPermission[]> {
    return this.driverRepository.GetDeliveryPermissions(driver_id);
  }

  AddDeliveryPermission(
    driver_id: string,
    promisor_id: string,
    contract_id: string,
    hub_id: string
  ): Promise<DeliveryPermission[]> {
    return this.driverRepository.AddDeliveryPermission(driver_id, promisor_id, contract_id, hub_id);
  }

  RemoveDeliveryPermission(
    driver_id: string,
    promisor_id: string,
    hub_id: string
  ): Promise<DeliveryPermission[]> {
    return this.driverRepository.RemoveDeliveryPermission(driver_id, promisor_id, hub_id);
  }
  GetOrganisationSettings(): Promise<Settings> {
    return this.driverRepository.GetOrganisationSettings();
  }
}
