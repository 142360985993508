import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';
import store from '../../../../core/redux/store';

export const editPaymentSettings = async (
  payment_setting_id: string,
  fee_per_trip: string,
  fee_per_order: string,
  base_fee: string,
  additional_fee: string
): Promise<any> => {
  try {
    const state = store.getState();
    const token = state.token.token;

    const uri = `${config.uri.base_url}/me/edit-payment-settings`;
    const options = {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        payment_setting_id,
        fee_per_trip,
        fee_per_order,
        base_fee,
        additional_fee
      })
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Something went wrong');
    }

    return result;
  } catch (err) {
    throw err;
  }
};
