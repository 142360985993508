import {
  DELIVERY_PERMISSIONS_LOAD_REQUEST,
  DELIVERY_PERMISSIONS_LOAD_SUCCESS,
  DELIVERY_PERMISSIONS_LOAD_FAILURE
} from '../types/delivery-permissions-types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  permissions: []
};

function delivery_permissions(state = initialState, action: any = null) {
  switch (action.type) {
    case DELIVERY_PERMISSIONS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case DELIVERY_PERMISSIONS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case DELIVERY_PERMISSIONS_LOAD_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
        success: true,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

export default delivery_permissions;
