import { Driver } from '../../../drivers/domain/entities/driver-entity';
import { Contract } from '../entities/contract-entity';
import { ContractRepository } from '../repositories/contract-repository';

interface ContractUsecase {
  GetContracts(): Promise<Contract[]>;
  GetContract(contract_id: string): Promise<Contract>;
  GetAssignedDrivers(contract_id: string): Promise<Driver[]>;
  GetDriversAssignedToHub(
    promisor_id: string,
    contract_id: string,
    hub_id: string
  ): Promise<Driver[]>;
  AddDriverToHub(
    promisor_id: string,
    contract_id: string,
    driver_id: string,
    hub_id: string
  ): Promise<Driver>;
  RemoveDriverFromHub(
    promisor_id: string,
    contract_id: string,
    driver_id: string,
    hub_id: string
  ): Promise<Driver>;
}

export class ContractUsecaseImpl implements ContractUsecase {
  contractRepository: ContractRepository;

  constructor(cr: ContractRepository) {
    this.contractRepository = cr;
  }

  GetContracts(): Promise<Contract[]> {
    return this.contractRepository.GetContracts();
  }

  GetContract(contract_id: string): Promise<Contract> {
    return this.contractRepository.GetContract(contract_id);
  }

  GetAssignedDrivers(contract_id: string): Promise<Driver[]> {
    return this.contractRepository.GetAssignedDrivers(contract_id);
  }

  GetDriversAssignedToHub(
    promisor_id: string,
    contract_id: string,
    hub_id: string
  ): Promise<Driver[]> {
    return this.contractRepository.GetDriversAssignedToHub(promisor_id, contract_id, hub_id);
  }

  AddDriverToHub(
    promisor_id: string,
    contract_id: string,
    driver_id: string,
    hub_id: string
  ): Promise<Driver> {
    return this.contractRepository.AddDriverToHub(promisor_id, contract_id, driver_id, hub_id);
  }

  RemoveDriverFromHub(
    promisor_id: string,
    contract_id: string,
    driver_id: string,
    hub_id: string
  ): Promise<Driver> {
    return this.contractRepository.RemoveDriverFromHub(promisor_id, contract_id, driver_id, hub_id);
  }
}
