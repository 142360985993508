// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

import * as FileSaver from 'file-saver';
import XSLX from 'sheetjs-style';

const nextLetter = (s) => {
  return s.replace(/([a-zA-Z])[^a-zA-Z]*$/, function (a) {
    var c = a.charCodeAt(0);
    switch (c) {
      case 90:
        return 'A';
      case 122:
        return 'a';
      default:
        return String.fromCharCode(++c);
    }
  });
};

const stringify = (drivers) => {
  // Iterate over keys, to get headings
  var row = [];
  var data = [];
  var headers = [];

  for (var driver of drivers) {
    Object.keys(driver).map(function (keyName, keyIndex) {
      if (keyName.toString() !== 'payment_setting') {
        headers.push(keyName);
      }
    });
    break;
  }

  // Iterate over Drivers, to get data
  for (var driver of drivers) {
    row = [];
    Object.keys(driver).map(function (keyName, keyIndex) {
      if (keyName.toString() !== 'payment_setting') {
        row.push(`${driver[keyName]}`);
      }
    });
    data.push(row);
  }
  return { headers, data };
};

const useStyles = makeStyles((theme) => ({
  fab: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 0,
    marginLeft: 0,
    zIndex: 5,
    width: '100%'
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const ExportActionButtonSize = ({ excelData, fileName }) => {
  const classes = useStyles();

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';

  const { headers, data } = stringify(excelData);
  const exportToExcel = async () => {
    const ws = XSLX.utils.book_new();
    XSLX.utils.sheet_add_aoa(ws, [headers]);
    XSLX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    let letter = 'A';
    let column_widths = [];

    for (let i = 1; i <= headers.length; i++) {
      const column = letter + '1';
      column_widths.push({ width: 40 });
      ws[column].s = {
        font: {
          bold: true,
          color: '#F2F2F2'
        }
      };
      letter = nextLetter(letter);
    }

    ws['!cols'] = column_widths;

    const excelBuffer = XSLX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
    const finalData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(finalData, fileName + '.xlsx');
  };

  return (
    <>
      <div className={classes.fab}>
        <div>
          <Button
            className={classes.margin}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth={true}
            onClick={(e) => exportToExcel()}
          >
            <PublishIcon /> Export
          </Button>
        </div>
      </div>
    </>
  );
};

export default ExportActionButtonSize;
