// @ts-check
import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Divider from '@material-ui/core/Divider';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import GroupIcon from '@material-ui/icons/Group';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  clientLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  icon: {
    fontSize: '4em'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

function Menu() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Paper className={classes.paper} elevation={0} onClick={() => history.push('/contracts')}>
          <AccountTreeIcon className={classes.icon} fontSize="large" />

          <div>
            <b>Contracts</b>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper} elevation={0} onClick={() => history.push('/drivers')}>
          <GroupIcon className={classes.icon} fontSize="large" />

          <div>
            <b>Drivers</b>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper} elevation={0} onClick={() => history.push('/account')}>
          <AccountCircleIcon className={classes.icon} fontSize="large" />

          <div>
            <b>Account</b>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default function HomePage() {
  const classes = useStyles();

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          <>
            <Menu />
            <Divider className={classes.divider} />
          </>
        </Container>
      </React.Fragment>
    </>
  );
}
