// @ts-check
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getContractAction } from '../redux/actions/contract-actions';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import Grid from '@material-ui/core/Grid';

import { LoadingContractsComponent } from '../components/loading-contracts-component';
import { ContractImageComponent } from '../components/contract-image-component';
import { ContractStatusComponent } from '../components/contract-status-component';
import { ContractDriversComponent } from '../components/contract-drivers-component';
import Typography from '@material-ui/core/Typography/Typography';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  heading: {
    margin: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(3)
  },
  driverHeader: {
    textAlign: 'center'
  },
  driverLogo: {
    height: '10rem',
    width: '10rem',
    objectFit: 'contain'
  },
  large: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  }
}));

function CenteredGrid({ contract }) {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <ContractImageComponent contract={contract} />
      </Grid>

      <Grid item xs={12} md={8}>
        <ContractStatusComponent contract={contract} />
        {contract.hubs.length > 0 && (
          <>
            <ContractDriversComponent contract={contract} />
          </>
        )}
        {contract.hubs.length === 0 && (
          <>
            <Typography
              variant="subtitle2"
              component="h3"
              align="center"
              className={classes.heading}
            >
              <small>
                <b>{contract.promisor.name} does not have hub's yet</b>
              </small>
              <br />
              <br />
              <SentimentVeryDissatisfiedIcon fontSize="large" />
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default function ContractPage() {
  // @ts-ignore
  const { id } = useParams();
  const classes = useStyles();

  const { contract, loading } = useSelector((state) => state.contract);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContractAction(id));
  }, [dispatch, id]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingContractsComponent />}
          {!loading && contract && (
            <>
              <BackNavigationComponent
                page="Contract"
                uri="/contracts"
                path={[
                  {
                    page: 'Home',
                    uri: '/'
                  },
                  {
                    page: 'Contracts',
                    uri: '/contracts'
                  }
                ]}
              />
              <CenteredGrid contract={contract} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
