import {
  CONTRACT_HUB_DRIVERS_LOAD_REQUEST,
  CONTRACT_HUB_DRIVERS_LOAD_SUCCESS,
  CONTRACT_HUB_DRIVERS_LOAD_FAILURE,
  CONTRACT_HUB_DRIVERS_ADD,
  CONTRACT_HUB_DRIVERS_REMOVE
} from '../types/contract-hub-drivers-types';

const initialState = {
  loading: false,
  error: null,
  contract_hub_drivers: []
};

function contract_hub_drivers(state = initialState, action: any = null) {
  switch (action.type) {
    case CONTRACT_HUB_DRIVERS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case CONTRACT_HUB_DRIVERS_LOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case CONTRACT_HUB_DRIVERS_LOAD_SUCCESS:
      return {
        ...state,
        contract_hub_drivers: action.payload,
        loading: false,
        error: null
      };

    case CONTRACT_HUB_DRIVERS_ADD:
      return {
        ...state,
        contract_hub_drivers: [action.payload, ...state.contract_hub_drivers]
      };

    case CONTRACT_HUB_DRIVERS_REMOVE:
      return {
        ...state,
        contract_hub_drivers: state.contract_hub_drivers.filter(
          (driver: any) => driver.id !== action.payload.id
        )
      };

    default:
      return state;
  }
}

export default contract_hub_drivers;
