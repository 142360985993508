import {
  CONTRACT_LOAD_REQUEST,
  CONTRACT_LOAD_SUCCESS,
  CONTRACT_LOAD_FAILURE,
  CONTRACT_LOAD_AVAILABLE_DRIVERS
} from '../types/contract-types';

const initialState = {
  loading: false,
  error: null,
  contract: null,
  drivers: []
};

function contract(state = initialState, action: any = null) {
  switch (action.type) {
    case CONTRACT_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case CONTRACT_LOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case CONTRACT_LOAD_SUCCESS:
      return {
        ...state,
        contract: action.payload,
        loading: false,
        error: null
      };

    case CONTRACT_LOAD_AVAILABLE_DRIVERS:
      return {
        ...state,
        drivers: action.payload
      };

    default:
      return state;
  }
}

export default contract;
