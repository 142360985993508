// @ts-check
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Chip from '@material-ui/core/Chip/Chip';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemSecondaryAction, Typography } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button/Button';
import ListItemAvatar from '@material-ui/core/ListItemAvatar/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar/Avatar';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import { addDriverToHubAction } from '../redux/actions/contract-actions';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { SelectedHubContext } from './selected-hub-context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  driverLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Driver({ driver, handleToggle }) {
  return (
    <>
      <ListItem button>
        <ListItemAvatar>
          <Avatar src={driver.image} alt={driver.name} />
        </ListItemAvatar>
        <ListItemText
          primary={`${driver.name}`}
          // secondary={driver.hub.name}
        ></ListItemText>

        <ListItemSecondaryAction>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => handleToggle(driver.id)}
          >
            Assign Driver
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}

function Permissions({ drivers, handleToggle }) {
  const classes = useStyles();

  const n = drivers.length - 1;
  const parties = drivers.map((driver, index) => {
    return (
      <div key={index}>
        <Driver driver={driver} handleToggle={handleToggle} />
        {index !== n && <Divider />}
      </div>
    );
  });

  return (
    <>
      <List className={classes.root}>{parties}</List>
    </>
  );
}

function DeliveryPermissions({ drivers, contract }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { state } = useContext(SelectedHubContext);

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  const { error } = useSelector((state) => state.loading);

  const showSnackbar = () => {
    setSnackbar(true);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const handleToggle = (driver_id) => {
    setBackdrop(true);
    const props = { closeBackdrop, showSnackbar };
    dispatch(
      addDriverToHubAction(contract.promisor.id, contract.id, driver_id, state.hub.id, props)
    );
  };

  return (
    <div className={classes.root}>
      <Backdrop
        className={classes.backdrop}
        open={backdrop}
        invisible={false}
        onClick={closeBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={snackbar} autoHideDuration={5000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={error ? 'error' : 'success'}>
          {error ? error.message : 'Driver assigned to hub!'}
        </Alert>
      </Snackbar>
      {drivers.length === 0 && (
        <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
          <small>no drivers</small>
        </Typography>
      )}
      {drivers.length > 0 && <Permissions drivers={drivers} handleToggle={handleToggle} />}
    </div>
  );
}

function AddDriversToHubheader() {
  const classes = useStyles();
  const { state } = useContext(SelectedHubContext);

  return (
    <div className={classes.margin}>
      <List className={classes.root}>
        <ListItem>
          <ListItemText primary={`${state.hub.name}`} secondary={null}></ListItemText>

          <ListItemSecondaryAction>
            <Chip color="primary" size="small" label="Hub" />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
}

export function AssignDriversToHubComponent({ contract }) {
  const classes = useStyles();
  const { drivers } = useSelector((state) => state.contract);
  const { contract_hub_drivers } = useSelector((state) => state.contract_hub_drivers);

  const available_drivers = drivers.filter(
    ({ id: id1 }) => !contract_hub_drivers.some(({ id: id2 }) => id2 === id1)
  );
  // const available_drivers = drivers;

  return (
    <div>
      <>
        <Divider />
        <AddDriversToHubheader />
        <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
          <small>
            <b>Assign Drivers To Hub</b>
          </small>
        </Typography>

        <DeliveryPermissions drivers={available_drivers} contract={contract} />
      </>
    </div>
  );
}
